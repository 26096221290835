import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import WizardHero from '@oup/shared-front-end/src/components/Wizard/WizardHero';

import withLocalizedContent from '../../../language/withLocalizedContent';
import { showSelfSelectRoleModal } from '../../../redux/actions/hubUi';

function OnboardingWizardSuccess({
  localizedContent: { selfRegisteredUserOnboardingWizard: selfRegisteredUserOnboardingWizardContent }
}) {
  return (
    <div data-testid="SELF_REGISTERED_USER_ONBOARDING_WIZARD_SUCCESS_CONTAINER">
      <WizardHero
        heading={{
          text: selfRegisteredUserOnboardingWizardContent.self_registered_user_success_step_title
        }}
        subHeading={selfRegisteredUserOnboardingWizardContent.self_registered_user_success_step_subtitle}
        image={{
          src: '/media/feedback/illustration-success.svg',
          alt: 'Onboarding successful'
        }}
      />
    </div>
  );
}

OnboardingWizardSuccess.propTypes = {
  localizedContent: PropTypes.object.isRequired
};

export default compose(
  connect(null, { showSelfSelectRoleModalAction: showSelfSelectRoleModal }),
  withLocalizedContent('selfRegisteredUserOnboardingWizard')
)(OnboardingWizardSuccess);
