import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Heading from '@oup/shared-front-end/src/components/Heading';
import styles from './HubListPageCredits.scss';
import { getLicenceCreditsProductsRequest } from '../../../redux/actions/licenceCreditsProducts.js';
import { toggleDetailsPopupWithId } from '../../../redux/reducers/assignLearningMaterial.reducer.js';
import colors from '../../../globals/colors';
import { featureIsEnabled } from '../../../globals/envSettings';
import { HubIllustrationConstants } from '../../../globals/hubConstants';
import { mode } from '../../../redux/reducers/licenceCreditsProducts.reducer.js';
import withLocalizedContent from '../../../language/withLocalizedContent';
import withDataRecency from '../../../dataRecency/withDataRecency';
import SubSectionSkeletonLoader from '../../../components/SkeletonLoader/SubSectionSkeletonLoader';
import CreditsListItem from '../../../components/ListItems/CreditsListItem/CreditsListItem.js';
import HubEmptyStateRestyle from '../../../components/HubEmptyStateRestyle/HubEmptyStateRestyle.js';

class HubListPageCredits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedProducts: {}
    };
  }

  componentDidMount() {
    this.checkOrgConfigAndInitialize();
  }

  handleItemClick = productid => {
    this.setState(prevState => {
      prevState.expandedProducts[productid] = !prevState.expandedProducts[productid];
      return { ...prevState };
    });
  };

  checkOrgConfigAndInitialize = () => {
    const { orgConfig } = this.props;
    if (orgConfig) {
      this.initializeList();
    } else {
      setTimeout(this.checkOrgConfigAndInitialize, 100);
    }
  };

  initializeList = async () => {
    const { getLicenceCreditsProducts, orgId } = this.props;
    await getLicenceCreditsProducts({ orgId });
  };

  render() {
    const {
      props: {
        localizedContent: { hubGlossary: hubContent },
        products,
        loadingResults,
        orgName,
        toggleDetailsPopupWithIdAction,
        errorResult
      }
    } = this;
    const licencesPageTitle = `${orgName} | ${hubContent.org_credits_header_licences}`;
    const creditsPurchased = `${hubContent.org_credits_purchased}`;
    const productCredits = `${hubContent.org_product_credits}`;
    const usedOrAvailableCredits = `${hubContent.org_product_credits_used_or_available}`;

    const emptyStateData = {
      iconSrc: HubIllustrationConstants.ENTER_CODE,
      title: hubContent.org_credits_empty_state_title,
      subtitle: hubContent.org_credits_empty_state_subtitle
    };

    const headingArgs = {
      text: hubContent.org_credits_header_licences,
      size: 'small'
    };

    return (
      <div>
        <Helmet title={licencesPageTitle} />
        <div className={styles.header}>{featureIsEnabled('navigation-changes') && <Heading {...headingArgs} />}</div>
        {errorResult && !loadingResults && (
          <HubEmptyStateRestyle
            iconSrc={emptyStateData.iconSrc}
            title={emptyStateData.title}
            bodyText={emptyStateData.subtitle}
          />
        )}

        {loadingResults && !errorResult && (
          <SubSectionSkeletonLoader
            tabName=""
            panelName=""
            speed={2}
            foregroundColor={colors.COLOR_GREY_DISABLED}
            backgroundColor={colors.COLOR_WHITE}
          />
        )}

        {!loadingResults && !errorResult && products?.length > 0 && (
          <>
            <p className={styles.creditsAvailable}>{creditsPurchased}</p>
            <div className={styles.productsHeader}>
              <p>{productCredits}</p>
              <p>{usedOrAvailableCredits}</p>
            </div>
            <ul>
              {products.map(product => (
                <li key={product.productid}>
                  <CreditsListItem
                    product={product}
                    // eslint-disable-next-line react/destructuring-assignment
                    isExpanded={this.state.expandedProducts[product.productid]}
                    dropdownActions={{ toggleDetails: toggleDetailsPopupWithIdAction }}
                    hubContent={hubContent}
                    onClick={this.handleItemClick}
                  />
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    );
  }
}

export default compose(
  withLocalizedContent('hubGlossary', 'HubListPageCredits'),
  withDataRecency('orgCredits'),
  connect(
    (state, { orgId }) => {
      const organisation = state.organisations.data[orgId] || {};
      return {
        products: state.licenceCreditsProducts.products,
        loadingResults: state.licenceCreditsProducts.organization.mode === mode.LOADING,
        errorResult: state.licenceCreditsProducts.organization.mode === mode.ERROR,
        orgName: organisation.name,
        orgId,
        orgConfig: organisation?.orgConfig,
        orgCode: organisation?.customId
      };
    },
    {
      toggleDetailsPopupWithIdAction: toggleDetailsPopupWithId,
      getLicenceCreditsProducts: orgId => getLicenceCreditsProductsRequest(orgId)
    }
  )
)(HubListPageCredits);

HubListPageCredits.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  products: PropTypes.object.isRequired,
  loadingResults: PropTypes.bool.isRequired,
  orgName: PropTypes.string.isRequired,
  toggleDetailsPopupWithIdAction: PropTypes.func.isRequired,
  orgId: PropTypes.string,
  getLicenceCreditsProducts: PropTypes.func,
  orgConfig: PropTypes.object,
  errorResult: PropTypes.string
};
