import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect, useSelector } from 'react-redux';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard';
import WizardHero from '@oup/shared-front-end/src/components/Wizard/WizardHero';
import ValidationMessage from '@oup/shared-front-end/src/components/ValidationMessage';

import { closeForm } from '../../../redux/reducers/placementTestSessionCreate';
import { closePlacementTestOnboardingWizard } from '../../../redux/actions/hubUi';
import { clearWizardState } from '../../../redux/actions/placementTestOnboardingWizard';
import withLocalizedContent from '../../../language/withLocalizedContent';
import { HubIllustrationAltText, HubIllustrationConstants } from '../../../globals/hubConstants';

function PlacementTestSummary({
  placementTestSessionNameValue,
  localizedContent: { placementTests: placementTestsContent }
}) {
  const { loading, setNextStepDisabled } = useWizard();

  setNextStepDisabled(loading);

  const studentsBulkImportIsInProgress = useSelector(
    state => state.placementTestSessionCreate.placementTest.studentsBulkImportIsInProgress
  );

  const getWizardStepTitle = () =>
    studentsBulkImportIsInProgress
      ? `${placementTestSessionNameValue ||
          placementTestsContent.placement_test_notification_page_session_name_fallback} ${
          placementTestsContent.placement_test_summary_page_title_bulk_in_progress
        }`
      : `${placementTestSessionNameValue ||
          placementTestsContent.placement_test_notification_page_session_name_fallback} ${
          placementTestsContent.placement_test_summary_page_title
        }`;

  const getWizardStepSubtitle = () =>
    studentsBulkImportIsInProgress ? (
      `${placementTestsContent.placement_test_summary_page_subtitle_bulk_in_progress}`
    ) : (
      <>
        {placementTestsContent.placement_test_summary_page_subtitle}{' '}
        <strong>{placementTestsContent.placement_test_summary_page_subtitle_manage_session}</strong>
      </>
    );

  return (
    <div data-testid="ONBOARDING_WIZARD_CREATE_A_PLACEMENT_TEST_SESSION_SUMMARY_CONTAINER">
      <WizardHero
        heading={{ text: getWizardStepTitle() }}
        subHeading={getWizardStepSubtitle()}
        image={{ src: HubIllustrationConstants.SUCCESS, alt: HubIllustrationAltText.SUCCESS }}
        content={
          studentsBulkImportIsInProgress && (
            <ValidationMessage state="information">
              {placementTestsContent.placement_test_summary_page_info_bulk_in_progress}
            </ValidationMessage>
          )
        }
      />
    </div>
  );
}

PlacementTestSummary.propTypes = {
  placementTestSessionNameValue: PropTypes.string,
  localizedContent: PropTypes.object.isRequired
};

export default compose(
  withLocalizedContent('placementTests'),
  connect(
    ({ placementTestSessionCreate }) => ({
      placementTestSessionNameValue: placementTestSessionCreate.placementTestSessionNameValue
    }),
    {
      clearWizardStateAction: clearWizardState,
      clearPlacementTestSessionCreateStateAction: closeForm,
      closeWizardAction: closePlacementTestOnboardingWizard
    }
  )
)(PlacementTestSummary);
