import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import userRoles, {
  orgUserRolesHighToLow,
  isTeacherAdminOrAbove,
  isTeacherOrAbove,
  selfRegisteredOrgUserRoles
} from '../globals/userRoles';

import LocationChangeListener from './locationChangeListener';
import HubLayout from './HubLayout';
import YoungLearnerLayout from './YoungLearnerLayout/YoungLearnerLayout';
import HubDashboardLayout from './HubDashboardLayout';
import HubIndexRedirectHandler from '../routes/hubIndexRedirectHandler';
import NotFoundView from '../views/errors/NotFoundView';
import { featureIsEnabled } from '../globals/envSettings';

// Hub Dashboard Page
import MyDashboard from '../routes/MyDashboard/MyDashboard';
import MyTasks from '../routes/MyTasks/MyTasks';
import HubProductLayout from './HubProductLayout/HubProductLayout';
import HubClassLayout from './HubClassLayout/HubClassLayout';
import MyCourses from '../routes/MyCourses/MyCourses';
import MyProgress from '../routes/MyProgress/MyProgress';
import RedeemCodePage from '../routes/RedeemCode/RedeemCodePage';
import RedeemCodeSuccessPage from '../routes/RedeemCode/RedeemCodeSuccessPage';
import RedeemCodePageWizard from '../routes/RedeemCodePage/RedeemCodePage';

// Hub Products Pages
import CourseMaterial from './HubProductLayout/CourseMaterial/CourseMaterial';
import ResourceCategory from './HubProductLayout/Resources/ResourceCategory';
import ResourceHome from './HubProductLayout/Resources/ResourceHome';
import ProductPage from '../routes/ProductPage/ProductPage';

// Hub Course assessments Pages
import AssessmentHome from './HubCourseAssessments/AssessmentHome/AssessmentHome';
import AssessmentFolderPage from './HubCourseAssessments/AssessmentFolderPage/AssessmentFolderPage';

// Hub Class related Pages
import MyClasses from '../routes/MyClasses/MyClasses';
import Classwork from './HubClassLayout/Classwork/Classwork';
import HubGradebook from './HubClassLayout/HubGradebook';
import StudentAnswerViewPage from '../routes/Gradebook/StudentAnswerViewPage';
import People from './HubClassLayout/People/People';
import ClassAssessment from './HubClassLayout/ClassAssessment/ClassAssessment';

// Hub Organization related Pages
import HubOrganizationLayout from './HubOrganizationLayout/HubOrganizationLayout';
import HubListPageStaff from './HubListPage/HubListPageStaff';
import HubListPageStudents from './HubListPage/HubListPageStudents';
import HubPersonProfilePage from './HubListPage/ProfilePage/HubPersonProfilePage';
import OrganizationClasses from './HubOrganizationLayout/OrganizationClasses/OrganizationClasses';
import OrganizationPlacementTests from './HubOrganizationLayout/OrganizationPlacementTests/OrganizationPlacementTests';
import OrganizationProgress from './HubOrganizationLayout/OrganizationProgress/OrganizationProgress';
import HubListPageLicences from './HubOrganizationLayout/OrganizationLicences/HubListPageLicences';
import LMSConfigTools from './HubOrganizationLayout/LMSConfig/LMSConfigTools';
import HubListPageCredits from './HubOrganizationLayout/OrganizationCredits/HubListPageCredits';

// Hub Support related pages
import HubSupportPage from '../routes/HubSupport/HubSupportPage';
import SupportArticlePage from '../routes/Support/SupportArticle/SupportArticlePage';

// Auth pages
import RegisterPage from '../routes/Register/RegisterPage';
import RequestPasswordReset from '../routes/RequestPasswordReset/RequestPasswordReset';
import ResetPassword from '../routes/ResetPassword/ResetPassword';
import RegisterChoice from '../routes/RegisterChoice/RegisterChoice';
import GeneralTermsAndConditions from '../routes/GeneralTermsAndConditions/GeneralTermsAndConditions';
import WelcomeToHubPage from '../routes/WelcomeToHubPage/WelcomeToHubPage';

// Blank page
import BlankPage from '../routes/BlankPage/BlankPage';
import StructuredContentPlayerBlankPage from '../routes/BlankPage/StructuredContentPlayerBlankPage';

// Redirect to Auth0
import LoginPage from '../routes/LoginPage/LoginPage';

import LaunchTokenLogoutPage from '../routes/LaunchTokenLogoutPage/LaunchTokenLogoutPage';

import protectedRoute from './protectedRoute';
import DeclineOrgInvitation from '../routes/DeclineOrgInvitation/DeclineOrgInvitation';

import { RegisterOrgPage, LegacyRegisterOrgPage } from '../pages';
import RegisterPageWizard from '../routes/RegisterPageWizard/RegisterPageWizard';
import RedeemCodeYoungLearnerPage from '../routes/RedeemCodeYoungLearnerPage/RedeemCodeYoungLearnerPage';

function RouteManager() {
  const hubRestrictedUserRoles = selfRegisteredOrgUserRoles;
  const teacherAdminOrAboveRoles = orgUserRolesHighToLow.filter(role => isTeacherAdminOrAbove(role));
  const teacherOrAboveRoles = orgUserRolesHighToLow.filter(role => isTeacherOrAbove(role));

  // TODO: Remove this once the feature flag is removed
  const getRegisterOrgPageComponent = () =>
    featureIsEnabled('register-org-restyle') ? <RegisterOrgPage /> : <LegacyRegisterOrgPage />;

  // TODO: Remove this once the feature flag is removed
  const getRedeemPageComponent = () =>
    featureIsEnabled('young-learners') ? (
      <RedeemCodePageWizard />
    ) : (
      protectedRoute(RedeemCodePage, false, Object.keys(userRoles), '/myDashboard/redeem')
    );

  // TODO: Remove this once the feature flag is removed
  const getRegisterWizardPageComponent = () =>
    featureIsEnabled('young-learners') ? (
      <RegisterPageWizard />
    ) : (
      protectedRoute(RegisterPage, false, Object.keys(userRoles), '/myDashboard')
    );

  const getYoungLearnersRedeemPage = () =>
    featureIsEnabled('young-learners') ? <RedeemCodeYoungLearnerPage /> : <BlankPage />;

  const registerWizardPageComponent = () => React.createElement(getRegisterWizardPageComponent);
  const redeemPageComponent = () => React.createElement(getRedeemPageComponent);
  const youngLearnersRedeemPage = () => React.createElement(getYoungLearnersRedeemPage);

  return (
    <BrowserRouter>
      <LocationChangeListener>
        <Switch>
          {/* Blank page for loading core assets only, used for OUP.VIEW.embed prior to .gotoPage(...) */}
          <Route path="blank">
            <BlankPage />
          </Route>

          {/* Logged out status page for LTI Tokens and Impersonation tokens */}
          <Route path="/logged-out">
            <HubLayout>
              <LaunchTokenLogoutPage />
            </HubLayout>
          </Route>

          {/* redirect to Auth0 */}
          <Route path="/login/:orgId?">
            <LoginPage />
          </Route>

          <Route exact path="/">
            <HubLayout>
              <HubIndexRedirectHandler />
            </HubLayout>
          </Route>

          <Route path="/launch/:role/:contentCode">
            <HubLayout>
              <StructuredContentPlayerBlankPage />
            </HubLayout>
          </Route>

          {/* Routes inherited from OUP */}
          <Route path="/terms-and-conditions/all">
            <HubLayout>
              <GeneralTermsAndConditions />
            </HubLayout>
          </Route>

          <Route path="/welcome-to-hub">
            <HubLayout>
              <WelcomeToHubPage />
            </HubLayout>
          </Route>

          <Route path="/register/:token?">
            <HubLayout>{registerWizardPageComponent()}</HubLayout>
          </Route>

          <Route path="/young-learner/register/:token?">
            <YoungLearnerLayout>
              <RegisterPageWizard />
            </YoungLearnerLayout>
          </Route>

          <Route path="/register-choice">
            <HubLayout>
              <RegisterChoice />
            </HubLayout>
          </Route>

          <Route path="/reset-password/:token">
            <HubLayout>
              <ResetPassword />
            </HubLayout>
          </Route>

          <Route path="/reset-password">
            <HubLayout>
              <RequestPasswordReset />
            </HubLayout>
          </Route>

          <Route path="/org/register">
            <HubLayout>
              {protectedRoute(getRegisterOrgPageComponent, true, Object.keys(userRoles), '/myDashboard')}
            </HubLayout>
          </Route>

          <Route path="/decline-invite/:token">
            <HubLayout>
              <DeclineOrgInvitation />
            </HubLayout>
          </Route>

          <Route path="/redeem/code">
            <HubLayout>
              <RedeemCodeSuccessPage />
            </HubLayout>
          </Route>

          <Route path="/redeem">
            <HubLayout>{redeemPageComponent()}</HubLayout>
          </Route>

          <Route path="/young-learner/redeem">
            <YoungLearnerLayout>{youngLearnersRedeemPage()}</YoungLearnerLayout>
          </Route>

          <Route path="/support/articles/:pageName?">
            <HubLayout>
              <SupportArticlePage />
            </HubLayout>
          </Route>

          <Route path="/support/:tabName?">
            <HubLayout>
              <HubSupportPage />
            </HubLayout>
          </Route>

          <Route path="/product/:resourceType?/:resourceId?">
            <HubLayout>
              <ProductPage />
            </HubLayout>
          </Route>

          <Route path="/myDashboard">
            <HubLayout>
              <HubDashboardLayout>{protectedRoute(MyDashboard, true, Object.keys(userRoles), '/')}</HubDashboardLayout>
            </HubLayout>
          </Route>

          <Route path="/myDashboard/redeem">
            <HubLayout>
              <HubDashboardLayout>{protectedRoute(MyDashboard, true, Object.keys(userRoles), '/')}</HubDashboardLayout>
            </HubLayout>
          </Route>

          <Route path="/myTasks/:view?">
            <HubLayout>
              <HubDashboardLayout>{protectedRoute(MyTasks, true, Object.keys(userRoles), '/')}</HubDashboardLayout>
            </HubLayout>
          </Route>

          <Route path="/myDashboard/classes">
            <HubLayout>
              <HubDashboardLayout>{protectedRoute(MyDashboard, true, Object.keys(userRoles), '/')}</HubDashboardLayout>
            </HubLayout>
          </Route>

          <Route path="/myProgress/:panelName?/:itemId?">
            <HubLayout>
              <HubDashboardLayout>{protectedRoute(MyProgress, true, Object.keys(userRoles), '/')}</HubDashboardLayout>
            </HubLayout>
          </Route>

          <Route path="/myCourses/singleCourse/:courseId/assessmentFolder/:assessmentId/:folder/assessmentHome">
            <HubLayout>
              <HubDashboardLayout>
                <HubProductLayout>{protectedRoute(AssessmentHome, true, Object.keys(userRoles), '/')}</HubProductLayout>
              </HubDashboardLayout>
            </HubLayout>
          </Route>

          <Route path="/myCourses/singleCourse/:courseId/assessmentFolder/:assessmentId/:folder/:subFolder">
            <HubLayout>
              <HubDashboardLayout>
                <HubProductLayout>
                  {protectedRoute(AssessmentFolderPage, true, Object.keys(userRoles), '/')}
                </HubProductLayout>
              </HubDashboardLayout>
            </HubLayout>
          </Route>

          <Route path="/myCourses/singleCourse/:courseId/resourceFolder/:folder/resourceHome">
            <HubLayout>
              <HubDashboardLayout>
                <HubProductLayout>{protectedRoute(ResourceHome, true, Object.keys(userRoles), '/')}</HubProductLayout>
              </HubDashboardLayout>
            </HubLayout>
          </Route>

          <Route path="/myCourses/singleCourse/:courseId/resourceFolder/:folder/getStartedHome">
            <HubLayout>
              <HubDashboardLayout>
                <HubProductLayout>{protectedRoute(ResourceHome, true, Object.keys(userRoles), '/')}</HubProductLayout>
              </HubDashboardLayout>
            </HubLayout>
          </Route>

          <Route path="/myCourses/singleCourse/:courseId/resourceFolder/:folder/professionalDevelopmentHome">
            <HubLayout>
              <HubDashboardLayout>
                <HubProductLayout>{protectedRoute(ResourceHome, true, Object.keys(userRoles), '/')}</HubProductLayout>
              </HubDashboardLayout>
            </HubLayout>
          </Route>

          <Route path="/myCourses/singleCourse/:courseId/resourceFolder/:folder/testHome">
            <HubLayout>
              <HubDashboardLayout>
                <HubProductLayout>{protectedRoute(ResourceHome, true, Object.keys(userRoles), '/')}</HubProductLayout>
              </HubDashboardLayout>
            </HubLayout>
          </Route>

          <Route path="/myCourses/singleCourse/:courseId/resourceFolder/:folder/:subFolder">
            <HubLayout>
              <HubDashboardLayout>
                <HubProductLayout>
                  {protectedRoute(ResourceCategory, true, Object.keys(userRoles), '/')}
                </HubProductLayout>
              </HubDashboardLayout>
            </HubLayout>
          </Route>

          <Route path="/myCourses/singleCourse/:courseId/resourceFolder/:folder">
            <HubLayout>
              <HubDashboardLayout>
                <HubProductLayout>
                  {protectedRoute(ResourceCategory, true, Object.keys(userRoles), '/')}
                </HubProductLayout>
              </HubDashboardLayout>
            </HubLayout>
          </Route>

          <Route path="/myCourses/singleCourse/:courseId/courseMaterial">
            <HubLayout>
              <HubDashboardLayout>
                <HubProductLayout>{protectedRoute(CourseMaterial, true, Object.keys(userRoles), '/')}</HubProductLayout>
              </HubDashboardLayout>
            </HubLayout>
          </Route>

          <Route path="/myCourses/singleCourse/:courseId">
            <HubLayout>
              <HubDashboardLayout>
                {protectedRoute(HubProductLayout, true, Object.keys(userRoles), '/')}
              </HubDashboardLayout>
            </HubLayout>
          </Route>

          <Route path="/myCourses">
            <HubLayout>
              <HubDashboardLayout>{protectedRoute(MyCourses, true, Object.keys(userRoles), '/')}</HubDashboardLayout>
            </HubLayout>
          </Route>

          <Route path="/myClasses/singleClass/:classroomId/classwork">
            <HubLayout>
              <HubDashboardLayout>
                <HubClassLayout>{protectedRoute(Classwork, true, hubRestrictedUserRoles, '/')}</HubClassLayout>
              </HubDashboardLayout>
            </HubLayout>
          </Route>

          <Route path="/myClasses/singleClass/:classroomId/assessment">
            <HubLayout>
              <HubDashboardLayout>
                <HubClassLayout>{protectedRoute(ClassAssessment, true, hubRestrictedUserRoles, '/')}</HubClassLayout>
              </HubDashboardLayout>
            </HubLayout>
          </Route>

          <Route path="/myClasses/singleClass/:classroomId/people/:personId">
            <HubLayout>
              <HubDashboardLayout>
                <HubClassLayout>
                  {protectedRoute(HubPersonProfilePage, true, hubRestrictedUserRoles, '/')}
                </HubClassLayout>
              </HubDashboardLayout>
            </HubLayout>
          </Route>

          <Route path="/myClasses/singleClass/:classroomId/people">
            <HubLayout>
              <HubDashboardLayout>
                <HubClassLayout>{protectedRoute(People, true, hubRestrictedUserRoles, '/')}</HubClassLayout>
              </HubDashboardLayout>
            </HubLayout>
          </Route>

          <Route path="/myClasses/singleClass/:classroomId/progress/:orgId?/:tabName?/:panelName?/:itemId?/:learnerId?">
            <HubLayout>
              <HubDashboardLayout>
                <HubClassLayout>
                  {protectedRoute(
                    HubGradebook,
                    true,
                    teacherOrAboveRoles,
                    '/myClasses/singleClass/:classroomId/classwork'
                  )}
                </HubClassLayout>
              </HubDashboardLayout>
            </HubLayout>
          </Route>

          <Route path="/myClasses/singleClass/:classroomId">
            <HubLayout>
              <HubDashboardLayout>
                {protectedRoute(HubClassLayout, true, hubRestrictedUserRoles, '/')}
              </HubDashboardLayout>
            </HubLayout>
          </Route>

          <Route path="/myClasses">
            <HubLayout>
              <HubDashboardLayout>{protectedRoute(MyClasses, true, Object.keys(userRoles), '/')}</HubDashboardLayout>
            </HubLayout>
          </Route>

          <Route path="/myOrganization/singleClass/:classroomId/people/:personId">
            <HubLayout>
              <HubDashboardLayout>
                <HubClassLayout>
                  {protectedRoute(HubPersonProfilePage, true, teacherAdminOrAboveRoles, '/')}
                </HubClassLayout>
              </HubDashboardLayout>
            </HubLayout>
          </Route>

          <Route path="/myOrganization/singleClass/:classroomId/people">
            <HubLayout>
              <HubDashboardLayout>
                <HubClassLayout>{protectedRoute(People, true, teacherAdminOrAboveRoles, '/')}</HubClassLayout>
              </HubDashboardLayout>
            </HubLayout>
          </Route>

          <Route path="/myOrganization/singleClass/:classroomId/classwork">
            <HubLayout>
              <HubDashboardLayout>
                <HubClassLayout>{protectedRoute(Classwork, true, teacherAdminOrAboveRoles, '/')}</HubClassLayout>
              </HubDashboardLayout>
            </HubLayout>
          </Route>

          <Route path="/myOrganization/singleClass/:classroomId/assessment">
            <HubLayout>
              <HubDashboardLayout>
                <HubClassLayout>{protectedRoute(ClassAssessment, true, teacherAdminOrAboveRoles, '/')}</HubClassLayout>
              </HubDashboardLayout>
            </HubLayout>
          </Route>

          <Route path="/myOrganization/singleClass/:classroomId/progress/:orgId?/:tabName?/:panelName?/:itemId?/:learnerId?">
            <HubLayout>
              <HubDashboardLayout>
                <HubClassLayout>{protectedRoute(HubGradebook, true, teacherAdminOrAboveRoles, '/')}</HubClassLayout>
              </HubDashboardLayout>
            </HubLayout>
          </Route>

          <Route path="/myOrganization/singleClass/:classroomId">
            <HubLayout>
              <HubDashboardLayout>
                {protectedRoute(HubClassLayout, true, teacherAdminOrAboveRoles, '/')}
              </HubDashboardLayout>
            </HubLayout>
          </Route>

          <Route path="/myOrganization/lmsConfig">
            <HubLayout>
              <HubDashboardLayout>
                <HubOrganizationLayout>
                  {protectedRoute(LMSConfigTools, true, teacherAdminOrAboveRoles, '/')}
                </HubOrganizationLayout>
              </HubDashboardLayout>
            </HubLayout>
          </Route>

          <Route path="/myOrganization/:list/:personId/:tab">
            <HubLayout>
              <HubDashboardLayout>
                <HubOrganizationLayout>
                  {protectedRoute(HubPersonProfilePage, true, teacherAdminOrAboveRoles, '/')}
                </HubOrganizationLayout>
              </HubDashboardLayout>
            </HubLayout>
          </Route>

          <Route path="/myOrganization/licences">
            <HubLayout>
              <HubDashboardLayout>
                <HubOrganizationLayout>
                  {protectedRoute(HubListPageLicences, true, teacherAdminOrAboveRoles, '/')}
                </HubOrganizationLayout>
              </HubDashboardLayout>
            </HubLayout>
          </Route>

          <Route path="/myOrganization/credits">
            <HubLayout>
              <HubDashboardLayout>
                <HubOrganizationLayout>
                  {protectedRoute(HubListPageCredits, true, teacherAdminOrAboveRoles, '/')}
                </HubOrganizationLayout>
              </HubDashboardLayout>
            </HubLayout>
          </Route>

          <Route path="/myOrganization/staff">
            <HubLayout>
              <HubDashboardLayout>
                <HubOrganizationLayout>
                  {protectedRoute(HubListPageStaff, true, teacherAdminOrAboveRoles, '/')}
                </HubOrganizationLayout>
              </HubDashboardLayout>
            </HubLayout>
          </Route>

          <Route path="/myOrganization/students">
            <HubLayout>
              <HubDashboardLayout>
                <HubOrganizationLayout>
                  {protectedRoute(HubListPageStudents, true, teacherAdminOrAboveRoles, '/')}
                </HubOrganizationLayout>
              </HubDashboardLayout>
            </HubLayout>
          </Route>

          <Route path="/myOrganization/classes">
            <HubLayout>
              <HubDashboardLayout>
                <HubOrganizationLayout>
                  {protectedRoute(OrganizationClasses, true, teacherAdminOrAboveRoles, '/')}
                </HubOrganizationLayout>
              </HubDashboardLayout>
            </HubLayout>
          </Route>

          <Route path="/myOrganization/placementTests">
            <HubLayout>
              <HubDashboardLayout>
                <HubOrganizationLayout>
                  {protectedRoute(OrganizationPlacementTests, true, teacherAdminOrAboveRoles, '/')}
                </HubOrganizationLayout>
              </HubDashboardLayout>
            </HubLayout>
          </Route>

          <Route path="/myOrganization/progress">
            <HubLayout>
              <HubDashboardLayout>
                <HubOrganizationLayout>
                  {protectedRoute(OrganizationProgress, true, teacherAdminOrAboveRoles, '/')}
                </HubOrganizationLayout>
              </HubDashboardLayout>
            </HubLayout>
          </Route>

          <Route path="/myOrganization">
            <HubLayout>
              <HubDashboardLayout>
                {protectedRoute(HubOrganizationLayout, true, teacherAdminOrAboveRoles, '/')}
              </HubDashboardLayout>
            </HubLayout>
          </Route>

          <Route path="/studentAnswerView/orgId/:orgId/class/:classroomId/:itemId/:learnerId/:bid/:uId/:activityId/:parentUId?">
            <HubLayout>
              <HubDashboardLayout>
                <StudentAnswerViewPage />
              </HubDashboardLayout>
            </HubLayout>
          </Route>

          {/* 404 */}
          <Route path="*">
            <HubLayout>
              <NotFoundView />
            </HubLayout>
          </Route>
        </Switch>
      </LocationChangeListener>
    </BrowserRouter>
  );
}

export default RouteManager;
