import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import WizardHero from '@oup/shared-front-end/src/components/Wizard/WizardHero';
import withLocalizedContent from '../../../language/withLocalizedContent';
import styles from './OnboardingWizardWelcome.scss';

function OnboardingWizardWelcome({
  localizedContent: { selfRegisteredUserOnboardingWizard: content, hubGlossary: hubContent },
  firstName
}) {
  return (
    <div className={styles.container} data-testid="SELF_REGISTERED_USER_ONBOARDING_WIZARD_WELCOME_CONTAINER">
      <WizardHero
        heading={{
          text: `${hubContent.self_select_role_title.replace('{firstName}', firstName)}`,
          size: 'large'
        }}
        subHeading={content.self_registered_user_onboarding_wizard_set_up}
        image={{
          src: '/media/feedback/illustration-welcome.svg',
          alt: 'Welcome to Oxford English Hub'
        }}
        variant="introduction"
      />
    </div>
  );
}

OnboardingWizardWelcome.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  firstName: PropTypes.string.isRequired
};

export default compose(withLocalizedContent('selfRegisteredUserOnboardingWizard', 'hubGlossary'))(
  OnboardingWizardWelcome
);
