import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import TextLink from '@oup/shared-front-end/src/components/TextLink';
import RadioButtonGroup from '@oup/shared-front-end/src/components/RadioButtonGroup';
import { ICON_ASSESSMENT, ICON_CLASS } from '@oup/shared-front-end/src/svg/oup';
import APP_CONSTANTS, { ORG_REGISTRATION_CONTEXT } from '@oup/shared-node-browser/constants';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard';
import Heading from '@oup/shared-front-end/src/components/Heading';

import styles from './ChooseOrgType.scss';
import withLocalizedContent from '../../../language/withLocalizedContent';
import { closeSelfRegisteredWizard } from '../../../redux/actions/hubUi';

function ChooseOrgType({
  localizedContent: { selfRegisteredUserOnboardingWizard: content },
  closeSelfRegisteredWizardAction,
  selectedOrgType,
  setSelectedOrgType
}) {
  const { setNextStepDisabled } = useWizard();

  useEffect(() => {
    setNextStepDisabled(!selectedOrgType);
  }, [selectedOrgType]);

  return (
    <div
      data-testid="SELF_REGISTERED_USER_ONBOARDING_WIZARD_CONFIRMATION_CONTAINER"
      className={styles.chooseOrgTypeStep}
    >
      <Heading
        text={content.self_registered_user_onboarding_wizard_choose_org_type_title}
        size="medium"
        className={styles.heading}
      />
      <RadioButtonGroup
        variant="outline"
        name="choose-type-of-org"
        legend="choose type of org"
        checkedValue={[selectedOrgType]}
        classnames={{ fieldset: styles.radioButtonGroupFieldset, container: styles.radioButtonGroupContainer }}
        radioGroup={[
          {
            id: 'teachingAndLearning',
            label: content.self_registered_user_onboarding_wizard_choose_org_type_teaching_and_learning_label,
            description: content.self_registered_user_onboarding_wizard_choose_org_type_teaching_and_learning_sublabel,
            value: ORG_REGISTRATION_CONTEXT.CES,
            image: <ICON_CLASS className={styles.RadiobuttonImage} aria-hidden="true" />
          },
          {
            id: 'assessment',
            label: content.self_registered_user_onboarding_wizard_choose_org_type_assessment_label,
            description: content.self_registered_user_onboarding_wizard_choose_org_type_assessment_sublabel,
            value: ORG_REGISTRATION_CONTEXT.PLACEMENT,
            image: <ICON_ASSESSMENT className={styles.RadiobuttonImage} aria-hidden="true" />
          }
        ]}
        onChange={e => setSelectedOrgType(e.target.value)}
      />
      {selectedOrgType === ORG_REGISTRATION_CONTEXT.CES && (
        <div className={styles.chooseOrgTypeDescription}>
          <div className={styles.images}>
            <div>
              <img src="/media/hub/teaching-and-learning.jpg" alt="Teaching and learning" />
            </div>
          </div>
          <div className={styles.text}>
            {content.self_registered_user_onboarding_wizard_choose_org_type_teaching_and_learning_description}
            <div className={styles.link}>
              <TextLink to={APP_CONSTANTS.HUB_SUPPORT_CONTACT_US} target="_blank">
                {content.staff_role_chooses_classes_and_courses_learn_more_link_text}
              </TextLink>
            </div>
          </div>
        </div>
      )}
      {selectedOrgType === ORG_REGISTRATION_CONTEXT.PLACEMENT && (
        <div className={styles.chooseOrgTypeDescription}>
          <div className={styles.images}>
            <div>
              <img src="/media/products/opt/opt.png" alt="Oxford Placement Test" />
            </div>
            <div>
              <img src="/media/products/opt/optyl.png" alt="Oxford Placement Test For Young Learners" />
            </div>
          </div>
          <div className={styles.text}>
            {content.self_registered_user_onboarding_wizard_choose_org_type_assessment_description}
            <div className={styles.link}>
              <TextLink to={APP_CONSTANTS.LEARN_MORE_ABOUT_OPT} target="_blank">
                {content.self_registered_user_onboarding_wizard_learn_more_about_opt}
              </TextLink>
              <TextLink to={APP_CONSTANTS.LEARN_MORE_ABOUT_OPTYL} target="_blank">
                {content.self_registered_user_onboarding_wizard_learn_more_about_optyl}
              </TextLink>
            </div>
          </div>
        </div>
      )}
      <TextLink
        component={RouterLink}
        onClick={e => {
          e.preventDefault();
          closeSelfRegisteredWizardAction();
        }}
      >
        {content.self_registered_user_what_to_do_next_continue_to_hub}
      </TextLink>
    </div>
  );
}

ChooseOrgType.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  closeSelfRegisteredWizardAction: PropTypes.func,
  setSelectedOrgType: PropTypes.func.isRequired,
  selectedOrgType: PropTypes.string.isRequired
};

export default compose(
  withLocalizedContent('selfRegisteredUserOnboardingWizard'),
  connect(null, {
    closeSelfRegisteredWizardAction: closeSelfRegisteredWizard
  })
)(ChooseOrgType);
