import React from 'react';
import ContentLoader from 'react-content-loader';

function PageHeroLoader() {
  return (
    <ContentLoader height="256" width="100%" viewBox="0 0 100% 256">
      <rect x="0" y="0" rx="5" ry="5" width="100%" height="256" />
    </ContentLoader>
  );
}

export default PageHeroLoader;
