import { HubLayoutConstants } from '../../../globals/hubConstants';
import { openEditOrgModal } from '../../../redux/actions/hubUi';
import { featureIsEnabled } from '../../../globals/envSettings';
import { isLtiMode } from '../../../utils/platform';

const { PATH_NAMES } = HubLayoutConstants;
// featureIsEnabled('hub-self-service')
const orgSubMenuConstants = {
  getOrgSubMenuItems: (content, { isLmsLtiTool = false, currentOrganisationLti = false }) => [
    ...(featureIsEnabled('hub-self-service') && isLmsLtiTool && !isLtiMode()
      ? [
          { name: content.org_lms_config_deployments, path: `${PATH_NAMES.ORGANIZATION_PATH}${PATH_NAMES.LMS_CONFIG}` },
          { name: content.org_menu_credits, path: `${PATH_NAMES.ORGANIZATION_PATH}${PATH_NAMES.CREDITS}` }
        ]
      : []),
    ...(featureIsEnabled('opt-main-features') && !currentOrganisationLti && !isLtiMode()
      ? [
          {
            name: content.progress,
            path: `${PATH_NAMES.ORGANIZATION_PATH}${PATH_NAMES.PROGRESS}`
          }
        ]
      : []),
    {
      name: content.org_menu_students,
      path: `${PATH_NAMES.ORGANIZATION_PATH}${PATH_NAMES.STUDENTS}`
    },
    { name: content.org_menu_staff, path: `${PATH_NAMES.ORGANIZATION_PATH}${PATH_NAMES.STAFF}` },
    { name: content.org_menu_classes, path: `${PATH_NAMES.ORGANIZATION_PATH}${PATH_NAMES.CLASSES}` },
    ...(featureIsEnabled('opt-main-features') && !currentOrganisationLti && !isLtiMode()
      ? [
          {
            name: content.org_menu_placementTests,
            path: `${PATH_NAMES.ORGANIZATION_PATH}${PATH_NAMES.PLACEMENT_TESTS}`
          }
        ]
      : []),
    { name: content.org_menu_licences, path: `${PATH_NAMES.ORGANIZATION_PATH}${PATH_NAMES.LICENCES}` }
  ],
  getManageOrgItems: content => [{ name: content.manage_organization, action: openEditOrgModal }]
};

export default orgSubMenuConstants;
