import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import ChooseRoleWizard from '../../components/ChooseRoleWizard/ChooseRoleWizard';
import RedeemCodeWizard from '../../components/RedeemCodeWizard/RedeemCodeWizard';

import withLocalizedContent from '../../language/withLocalizedContent';
import mountLiveChat, { unmountLiveChat } from '../../utils/chat/liveChat';

function RedeemCodePage({ localizedContent: { redeemCodePage: content }, userId }) {
  const { push } = useHistory();
  const [isChooseRoleWizardOpen, setIsChooseRoleWizardOpen] = useState(true);
  const [isRedeemCodeWizardOpen, setIsRedeemCodeWizardOpen] = useState(false);

  useEffect(() => {
    if (userId) {
      push('/myDashboard/redeem');
    }

    mountLiveChat();

    return () => {
      unmountLiveChat();
    };
  }, []);

  const onStandardRoleChosen = () => {
    setIsChooseRoleWizardOpen(false);
    setIsRedeemCodeWizardOpen(true);
  };

  const onYoungLearnerChosen = () => {
    setIsChooseRoleWizardOpen(false);
    push('/young-learner/redeem');
  };

  return (
    <div>
      <Helmet title={content.page_title} />
      <ChooseRoleWizard
        isOpen={isChooseRoleWizardOpen}
        onRoleChosen={role => {
          if (role === 'standard') {
            onStandardRoleChosen();
          } else if (role === 'youngLearner') {
            onYoungLearnerChosen();
          }
        }}
      />
      <RedeemCodeWizard isOpen={isRedeemCodeWizardOpen} />
    </div>
  );
}

RedeemCodePage.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  userId: PropTypes.string
};

export default compose(
  connect(state => ({
    userId: state.identity.userId
  })),
  withLocalizedContent('redeemCodePage')
)(RedeemCodePage);
