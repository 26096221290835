import { PLACEMENT_NOTIFICATIONS_TYPES } from '@oup/shared-node-browser/constants.js';

const { BEFORE_TEST_STARTS, BEFORE_TEST_ENDS } = PLACEMENT_NOTIFICATIONS_TYPES;

export const OPT_YL = 'OPT_YL';
export const OPT = 'OPT';

export const LANGUAGE_VARIATION = {
  OPT: 'Oxford Placement Test'
};

export const DEFAULT_REMINDERS = [
  { notificationType: 'EMAIL', timePeriod: 'select', notificationEvent: BEFORE_TEST_STARTS },
  { notificationType: 'EMAIL', timePeriod: 'select', notificationEvent: BEFORE_TEST_ENDS }
];

export const INTERVAL_OPTIONS = [
  {
    value: 'select',
    text: 'Please select'
  },
  {
    value: 12,
    text: '12 hours'
  },
  {
    value: 24,
    text: '1 day'
  },
  {
    value: 72,
    text: '3 days'
  },
  {
    value: 168,
    text: '1 week'
  }
];

export const MOMENT_OPTIONS = [
  {
    value: BEFORE_TEST_STARTS,
    text: 'before test session starts'
  },
  {
    value: BEFORE_TEST_ENDS,
    text: 'before test session ends'
  }
];
