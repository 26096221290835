import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { compose } from 'recompose';

import PropTypes from 'prop-types';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import NumberInput from '@oup/shared-front-end/src/components/NumberInput/NumberInput';
import styles from '../ManageJoiningCode.scss';
import withLocalizedContent from '../../../../../../language/withLocalizedContent';
import getOptProductsCatalogueUrl from '../../../../../../utils/opt/getOptProductsCatalogueUrl';

function PlacesRemainingInSession({
  localizedContent: { manageJoiningCode: content, placementTests: placementTestsContent },
  enabled,
  places,
  error,
  setError,
  availableCredits,
  placesForUnknownStudents,
  differenceFromMax,
  setDifferenceFromMax,
  setPlaces
}) {
  const currentOrganisationId = useSelector(state => state.identity.currentOrganisationId);
  const { customId: customOrgId = '' } = useSelector(state => state.organisations.data[currentOrganisationId]);

  const onChangeHandler = e => {
    const newVal = Number(e.target.value);
    if (!Number.isNaN(newVal)) setPlaces(newVal);
  };

  useEffect(() => {
    if (places > availableCredits + placesForUnknownStudents) {
      setDifferenceFromMax();
      setError(true);
    } else {
      setError(false);
    }
  }, [places]);

  return (
    <div>
      <div className={styles.placesRemaining}>
        <span className={styles.placesRemainingText}>{content.placesRemainingInSession}</span>
        <NumberInput
          disabled={!enabled}
          onChange={onChangeHandler}
          label={placementTestsContent.placement_test_set_number_of_places_for_unknown_students_label}
          value={places}
          min={0}
          max={100000}
        />
      </div>

      {error && (
        <div className={styles.errorMessageContainer}>
          <p>{`${content.notEnoughLicences} ${differenceFromMax} ${content.additionalLicencesNeeded} (${availableCredits}
            ${content.licencesRemaining}).`}</p>
          <TextLink to={getOptProductsCatalogueUrl(customOrgId)} target="_blank">
            {placementTestsContent.placement_test_add_students_page_buy_more_link_text}
          </TextLink>
        </div>
      )}
    </div>
  );
}

PlacesRemainingInSession.propTypes = {
  localizedContent: PropTypes.object,
  enabled: PropTypes.bool,
  places: PropTypes.number,
  error: PropTypes.bool,
  setError: PropTypes.func,
  availableCredits: PropTypes.number,
  placesForUnknownStudents: PropTypes.number,
  differenceFromMax: PropTypes.number,
  setDifferenceFromMax: PropTypes.func,
  setPlaces: PropTypes.func
};

export default compose(
  withLocalizedContent('manageJoiningCode', 'placementTests'),
  connect(
    ({ loadPlacementTestsReducer: { codeDetails } }) => ({
      codeDetails
    }),
    {}
  )
)(PlacesRemainingInSession);
