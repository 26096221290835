import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import RadioButton from '@oup/shared-front-end/src/components/RadioButton';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import { Link as RouterLink } from 'react-router-dom';

import { ORG_REGISTRATION_CONTEXT } from '@oup/shared-node-browser/constants';
import HubModalLayout from '../../HubModalLayout/HubModalLayout';
import Button, { buttonTypes } from '../../Button/Button';
import styles from './CreateFirstPlacementTestOrClassModal.scss';
import withLocalizedContent from '../../../language/withLocalizedContent';
import { HubLayoutConstants } from '../../../globals/hubConstants';

function CreateFirstPlacementTestOrClassModal({
  isOpen,
  onClose,
  onNext,
  localizedContent: { selfRegisteredUserOnboardingWizard: content, hubGlossary: hubContent },
  wizardType
}) {
  const modalRef = useRef(null);
  const selectionInitialState = '';
  const [selectedOption, setSelectedOption] = useState(selectionInitialState);
  const isDisabledNextBtn = !selectedOption;
  const wizard = wizardType.toUpperCase();
  const isPlacementCentre = wizardType === ORG_REGISTRATION_CONTEXT.PLACEMENT;

  const handleOptionSelected = e => {
    setSelectedOption(e.target.value);
  };

  const handleNextBtn = () => {
    onNext(selectedOption);
  };

  const handleClose = () => {
    setSelectedOption(selectionInitialState);
    onClose();
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (modalRef.current && !modalRef.current.contains(event.target)) handleClose();
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className={`${styles.outerWizardWarningModal} ${isOpen ? styles.open : ''}`}>
      <HubModalLayout
        isOpen={isOpen}
        closeAction={onClose}
        focusOnFirstField={false}
        ariaLabel={content.self_registered_user_what_to_do_next_title}
      >
        <div
          data-testid={`SELF_REGISTERED_USER_CREATE_${wizard}_MODAL`}
          className={`${styles.modalContainer} ${isOpen ? styles.animationFadeIn : ''}`}
          aria-hidden={!isOpen}
          ref={modalRef}
        >
          <div className={styles.modalHeader}>
            <Button
              dataTestId={`SELF_REGISTERED_USER_CREATE_${wizard}_MODAL_CLOSE_BUTTON`}
              text={hubContent.close}
              type={buttonTypes.CLOSE_BORDER_GRAY}
              onClick={handleClose}
            />
            <h2 className={styles.title}>{content.self_registered_user_what_to_do_next_title}</h2>
          </div>
          <div className={styles.modalContent}>
            <div className={styles.option}>
              {!isPlacementCentre ? (
                <RadioButton
                  variant="outline"
                  name="self-registered-user-class-option"
                  legend="legend name test 1"
                  id="self-registered-user-radio-class"
                  label={content.self_registered_user_create_class_modal_option_label}
                  description={content.self_registered_user_create_class_modal_option_subLabel}
                  value="class"
                  onChange={handleOptionSelected}
                />
              ) : (
                <RadioButton
                  variant="outline"
                  name="self-registered-user-placement-test-option"
                  legend="legend name test 1"
                  id="self-registered-user-radio-placementTest"
                  label={content.self_registered_user_create_placement_test_modal_option_label}
                  description={content.self_registered_user_create_placement_test_modal_option_subLabel}
                  value={ORG_REGISTRATION_CONTEXT.PLACEMENT}
                  onChange={handleOptionSelected}
                />
              )}
            </div>
            <TextLink
              component={RouterLink}
              onClick={e => {
                e.preventDefault();
                window.location = HubLayoutConstants.PATH_NAMES.DASHBOARD_PATH;
              }}
            >
              {content.self_registered_user_what_to_do_next_continue_to_hub}
            </TextLink>
          </div>
          <div className={styles.modalFooter}>
            <TextLink
              dataTestId={`SELF_REGISTERED_USER_CREATE_${wizard}_MODAL_CANCEL_BUTTON`}
              onClick={e => {
                e.preventDefault();
                handleClose();
              }}
            >
              {hubContent.cancel}
            </TextLink>
            <Button
              dataTestId={`SELF_REGISTERED_USER_CREATE_${wizard}_MODAL_CONFIRM_BUTTON`}
              text={hubContent.next}
              type={buttonTypes.PRIMARY}
              onClick={handleNextBtn}
              disabled={isDisabledNextBtn}
            />
          </div>
        </div>
      </HubModalLayout>
    </div>
  );
}

CreateFirstPlacementTestOrClassModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired,
  wizardType: PropTypes.string.isRequired
};

export default compose(withLocalizedContent('selfRegisteredUserOnboardingWizard', 'hubGlossary'))(
  CreateFirstPlacementTestOrClassModal
);
