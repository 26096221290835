import React from 'react';
import PropTypes from 'prop-types';
import styles from './ClassCode.scss';
import CopyToClipboardButton from '../../CopyToClipboardButton/CopyToClipboardButton.js';

function ClassCode({ customOrgId, joiningCode }) {
  return (
    <div className={styles.classCode}>
      <span>
        <span className={styles.firstPartCode}>{customOrgId}</span>
        <span>-{joiningCode}</span>
      </span>
      <CopyToClipboardButton
        classCode={`${customOrgId}-${joiningCode}`}
        customButtonColor="#002147"
        dataAttributes={{ testId: 'CREATE_CLASS_WIZARD_COPY_CLASS_CODE_BUTTON' }}
        customClassname={styles.customClassCode}
      />
    </div>
  );
}

ClassCode.propTypes = {
  customOrgId: PropTypes.string.isRequired,
  joiningCode: PropTypes.string.isRequired
};

export default ClassCode;
