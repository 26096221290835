import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import styles from '../ListItems.scss';
import { licenceStatus as HubLayoutConstants } from '../../../globals/hubConstants.js';
import getDropdownItems from '../../HubDropdownMenu/Services/getDropdownItems';
import HubDropdownMenu from '../../HubDropdownMenu/HubDropdownMenu';
import HubLabel from '../../HubLabel/HubLabel';
import SVGIcon, { GLYPHS } from '../../SVGIcon/SVGIcon';
import { openDownloadProductModal } from '../../../redux/actions/hubUi.js';
import { loadCptContentRequest, setContent } from '../../../redux/actions/structuredContentPlayer';
import { isLocal } from '../../../globals/envSettings';

function CreditsListItem({ product, hubContent, onClick, isExpanded, dropdownActions: { toggleDetails } }) {
  const { title = '' } = product;

  const dropdownOptions = {};
  const isPackProduct = product.creditsPack?.length > 0;
  const actions = {
    toggleDetails: e => {
      e.preventDefault();
      e.stopPropagation();
      toggleDetails(product, null, null, {});
    }
  };

  const addFallbackCover = ev => {
    if (isLocal()) {
      return;
    }
    ev.target.src = '/static/images/offline/default-cover.gif';
  };

  return (
    <div data-list="product" data-testid="LICENCE_LIST_ITEM" className={styles.collapsed}>
      <div
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        onClick={() => onClick(product.productid)}
        role="none"
        className={classnames(
          styles.product,
          styles.topLevel,
          { [styles[HubLayoutConstants] || '']: HubLayoutConstants },
          isExpanded && isPackProduct ? styles.creditsBackground : ''
        )}
      >
        <div className={styles.leftContainerWide}>
          <div className={`${styles.imgContainer}`} style={{ overflow: 'visible' }}>
            <img src={`${product.cover}`} alt="" onError={addFallbackCover} />
          </div>
          <div className={`${styles.textContainer} `} style={{ justifyContent: 'center' }}>
            {isPackProduct && (
              <div className={styles.labelsCreditsContainer}>
                <HubLabel
                  customClassName={styles.labelPillDown}
                  text={
                    product.target_usertype === 'teacher'
                      ? `${hubContent.hub_label_teacher_collection}`
                      : `${hubContent.hub_label_student_collection}`
                  }
                  PrefixIcon={true && <SVGIcon glyph={GLYPHS.CHEVRON_DOWN} />}
                />
                <span className={styles.linkedProductsIcon}>
                  <SVGIcon glyph={GLYPHS.ICON_COURSE} />
                  <span>{product.creditsPack.length}</span>
                </span>
              </div>
            )}
            <span className={styles.creditsTitle}>{title}</span>
          </div>

          <div className={styles.creditsPill}>
            <span className={styles.used}>{product.usedCredits}</span>
            <span className={styles.available}>{product.credits}</span>
          </div>

          <div className={styles.dropdownContainer} style={{ marginLeft: '2.5%' }}>
            <HubDropdownMenu dropdownData={getDropdownItems('productList', hubContent, dropdownOptions, actions)} />
          </div>
        </div>
      </div>

      {isPackProduct && isExpanded && (
        <div className={styles.packContainer}>
          {product.creditsPack.map(prod => (
            <div key={prod.isbn} className={styles.packChild}>
              <img src={prod.cover} alt="" onError={addFallbackCover} />
              <div>
                <div className={styles.labelsCreditsContainer}>
                  <HubLabel
                    customClassName={styles.labelPillDown}
                    text={
                      prod.target_usertype === 'teacher'
                        ? `${hubContent.hub_label_teacher_material}`
                        : `${hubContent.hub_label_student_material}`
                    }
                  />
                  <p className={styles.creditsTitle}>{prod.title}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

CreditsListItem.defaultProps = {
  dropdownActions: {}
};

CreditsListItem.propTypes = {
  product: PropTypes.object,
  hubContent: PropTypes.object,
  isExpanded: PropTypes.bool,
  onClick: PropTypes.func,
  dropdownActions: PropTypes.object
};

const mapStateToProps = ({ hubUi: { downloadProductModalOpen } }) => ({
  downloadProductModalOpen
});

const mapDispatchToProp = {
  openDownloadProductModalAction: openDownloadProductModal,
  loadCptContentRequestAction: loadCptContentRequest,
  setContentAction: setContent
};

export default compose(connect(mapStateToProps, mapDispatchToProp))(CreditsListItem);
