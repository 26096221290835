import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { useWizard } from '@oup/shared-front-end/src/components/Wizard';
import TextInput from '@oup/shared-front-end/src/components/TextInput/TextInput.js';
import Heading from '@oup/shared-front-end/src/components/Heading';

import styles from './FirstLastNameStep.scss';

import { setFirstName, setLastName } from '../../../redux/reducers/registration/registration.reducer.js';
import appSettings from '../../../globals/appSettings.js';
import withLocalizedContent from '../../../language/withLocalizedContent.js';

function FirstAndLastNameStep({
  localizedContent: { registrationPage: content },
  isYoungLearner,
  firstNameValue,
  lastNameValue,
  setFirstNameAction,
  setLastNameAction
}) {
  const { setNextStepDisabled } = useWizard();
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [firstNameFieldState, setFirstNameFieldState] = useState('default');
  const [lastNameFieldState, setLastNameFieldState] = useState('default');

  useEffect(() => {
    if (firstNameFieldState === 'valid' && lastNameFieldState === 'valid') {
      setNextStepDisabled(false);
    } else {
      setNextStepDisabled(true);
    }
  }, [firstNameFieldState, lastNameFieldState]);

  useEffect(() => {
    setNextStepDisabled(!(firstNameValue && lastNameValue));
  }, []);

  const validateName = (value = '') => {
    const trimmedValue = value.trim();
    const invalidName = trimmedValue.length <= 1;
    const invalidCharacters = appSettings.INVALID_CHARACTERS_REGEX.test(trimmedValue);

    return { invalidName, invalidCharacters };
  };

  return (
    <div data-testid="REGISTER_WIZARD_NAME_STEP" className={styles.firstLastNameStepContainer}>
      <div className={styles.container}>
        <Heading
          text={isYoungLearner ? content.yl_wizard_first_step_title : content.wizard_first_step_title}
          size="medium"
          className={styles.heading}
        />

        <TextInput
          name="firstName"
          required
          label={isYoungLearner ? content.yl_first_name_label : content.first_name_label}
          subLabel={content.required_sub_label}
          value={firstNameValue}
          onBlur={({ target: { value } }) => {
            const { invalidName, invalidCharacters } = validateName(value);

            if (invalidName) {
              setFirstNameError(content.forename_required_text);
              setFirstNameFieldState('invalid');
              return;
            }

            if (invalidCharacters) {
              setFirstNameError(content.invalid_name_error);
              setFirstNameFieldState('invalid');
              return;
            }

            if (!invalidName && !invalidCharacters) {
              setFirstNameError('');
              setFirstNameFieldState('valid');
            }
          }}
          onChange={({ target: { value } }) => {
            setFirstNameAction(value);
          }}
          state={firstNameFieldState}
          validationMessage={firstNameFieldState === 'valid' ? '' : firstNameError}
          dataAttributes={{ testId: 'WIZARD_REGISTER_FIRST_NAME' }}
        />
        <TextInput
          name="lastName"
          required
          label={isYoungLearner ? content.yl_last_name_label : content.last_name_label}
          subLabel={content.required_sub_label}
          value={lastNameValue}
          onBlur={({ target: { value } }) => {
            const { invalidName, invalidCharacters } = validateName(value);

            if (invalidName) {
              setLastNameError(content.surname_required_text);
              setLastNameFieldState('invalid');
              return;
            }

            if (invalidCharacters) {
              setLastNameError(content.invalid_name_error);
              setLastNameFieldState('invalid');
              return;
            }

            if (!invalidName && !invalidCharacters) {
              setLastNameError('');
              setLastNameFieldState('valid');
            }
          }}
          onChange={({ target: { value } }) => {
            setLastNameAction(value);
          }}
          state={lastNameFieldState}
          validationMessage={lastNameFieldState === 'valid' ? '' : lastNameError}
          dataAttributes={{ testId: 'WIZARD_REGISTER_LAST_NAME' }}
        />
      </div>
    </div>
  );
}

FirstAndLastNameStep.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  isYoungLearner: PropTypes.bool,
  firstNameValue: PropTypes.string.isRequired,
  lastNameValue: PropTypes.string.isRequired,
  setFirstNameAction: PropTypes.func.isRequired,
  setLastNameAction: PropTypes.func.isRequired
};

export default compose(
  withLocalizedContent('registrationPage'),
  connect(
    state => ({
      firstNameValue: state.registration.firstNameValue,
      lastNameValue: state.registration.lastNameValue,
      isYoungLearner: window.location.href.includes('young-learner') // to be replaced with something more robust in future
    }),
    {
      setFirstNameAction: setFirstName,
      setLastNameAction: setLastName
    }
  )
)(FirstAndLastNameStep);
