import React from 'react';
import PropTypes from 'prop-types';
import WizardHero from '@oup/shared-front-end/src/components/Wizard/WizardHero';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../../globals/hubConstants';
import styles from './WizardIntroScreen.scss';

function WizardIntroScreen({ content }) {
  return (
    <div data-testid="CREATE_CLASS_WIZARD_INTRO_STEP" className={styles.stepContainer}>
      <WizardHero
        className={styles.introStep}
        heading={{ text: content.set_up_a_class }}
        subHeading={content.create_class_wizard_intro_subheading}
        image={{ src: HubIllustrationConstants.ORGANIZING, alt: HubIllustrationAltText.ORGANIZING }}
        variant="introduction"
        content={
          <div className={styles.textContainer}>
            <ol>
              <li>
                <p>{content.create_class_intro_content_1}</p>
                <p>{content.create_class_intro_content_subtext_1}</p>
              </li>
              <li>
                <p>{content.create_class_intro_content_2}</p>
                <p>{content.create_class_intro_content_subtext_2}</p>
              </li>
              <li>
                <p>{content.create_class_intro_content_3}</p>
                <p>{content.create_class_intro_content_subtext_3}</p>
              </li>
            </ol>
            <TextLink to={content.learn_more_about_classes_link} target="_blank">
              {content.learn_more_about_classes_link_text}
            </TextLink>
          </div>
        }
      />
    </div>
  );
}

WizardIntroScreen.propTypes = {
  content: PropTypes.object.isRequired
};

export default WizardIntroScreen;
