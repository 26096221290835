import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PageHero from '@oup/shared-front-end/src/components/PageHero';
import TextLink from '@oup/shared-front-end/src/components/TextLink/TextLink.js';
import { roleIsAtLeast } from '@oup/shared-node-browser/user.js';
import { openSelfRegisteredWizard, showSelfSelectRoleModal } from '../../redux/actions/hubUi';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants.js';
import styles from './HubMyDashboardBanner.scss';
import userRoles, { getSelfSelectedRole, isTeacherOrAbove } from '../../globals/userRoles';
import localStorageWithCache from '../../utils/localStorageWithCache';
import { featureIsEnabled } from '../../globals/envSettings';
// HOC
import withLocalizedContent from '../../language/withLocalizedContent';
import PageHeroLoader from '../SkeletonLoader/Hub/PageHeroLoader.js';

const handleOnLinkClick = showSelfSelectRoleModalAction => {
  const { removeKey } = localStorageWithCache;
  removeKey('selectedRoleData');
  showSelfSelectRoleModalAction(true);
};

const getSelfSelectRoleLink = (userRole, hubContent, showSelfSelectRoleModalAction) => {
  if (roleIsAtLeast(userRoles.LEARNER, userRole)) return '';

  switch (getSelfSelectedRole()) {
    case 'SELF_LEARNER':
      return {
        text: hubContent.self_learner_link,
        onClick: () => handleOnLinkClick(showSelfSelectRoleModalAction)
      };
    case 'SELF_TEACHER':
      return {
        text: hubContent.self_teacher_link,
        onClick: () => handleOnLinkClick(showSelfSelectRoleModalAction)
      };
    default:
      if (userRole === userRoles.USER) {
        return {
          text: hubContent.choose_a_role,
          onClick: () => handleOnLinkClick(showSelfSelectRoleModalAction)
        };
      }

      return '';
  }
};

const getSubtitleText = (userRole, hubContent, orgName) => {
  let roleBasedText = '';
  let selfSignInText = hubContent.self_sign_in_text;
  const selfSelectedRole = getSelfSelectedRole();
  if (isTeacherOrAbove(userRole) || (userRole === userRoles.USER && selfSelectedRole === 'SELF_TEACHER')) {
    roleBasedText = `<strong>${hubContent.teacher_administrator}</strong>.`;
  } else if (userRole === userRoles.LEARNER || selfSelectedRole === 'SELF_LEARNER') {
    roleBasedText = `<strong>${hubContent.student}</strong>.`;
  } else if (!selfSelectedRole && userRole === userRoles.USER) {
    selfSignInText = hubContent.havent_chosen_a_role_yet;
  }

  return orgName
    ? `${hubContent.general_greeting}</br> ${hubContent.signed_in_message} <strong>${orgName}</strong> as a ${roleBasedText}`
    : `${hubContent.general_greeting}</br>  ${selfSignInText} ${roleBasedText} `;
};

const getRoleBasedContent = (userRole, hubContent, orgName, showSelfSelectRoleModalAction) => {
  const link = getSelfSelectRoleLink(userRole, hubContent, showSelfSelectRoleModalAction);
  const subtitle = getSubtitleText(userRole, hubContent, orgName, showSelfSelectRoleModalAction);

  return (
    <p>
      {/* eslint-disable-next-line react/no-danger */}
      <span dangerouslySetInnerHTML={{ __html: subtitle }} />
      <TextLink onClick={() => link.onClick()}>{link.text}</TextLink>
    </p>
  );
};
function HubMyDashboardBanner({
  firstName,
  userRole,
  orgName,
  showSelfSelectRoleModalAction,
  openSelfRegisteredWizardAction,
  localizedContent: { hubGlossary: hubContent },
  areOrgLoading,
  areCoursesLoading,
  areClassesLoading
}) {
  const [childrenContent, setChildrenContent] = useState('');
  const isLoading = areOrgLoading || areCoursesLoading || areClassesLoading;

  const pageHeroProps = {
    title: `${hubContent.hello}, ${firstName}`,
    illustration: HubIllustrationConstants.DASHBOARD,
    illustrationAltText: HubIllustrationAltText.DASHBOARD,
    className: styles.neutralBlue,
    children: childrenContent
  };

  const selfRole = getSelfSelectedRole();

  useEffect(() => {
    setChildrenContent(
      getRoleBasedContent(
        userRole,
        hubContent,
        orgName,
        featureIsEnabled('opt-main-features') ? openSelfRegisteredWizardAction : showSelfSelectRoleModalAction,
        userRole === userRoles.USER && featureIsEnabled('opt-main-features')
      )
    );
  }, [userRole, selfRole]);

  return (
    <div className={styles.pageHeroContainer}>{isLoading ? <PageHeroLoader /> : <PageHero {...pageHeroProps} />}</div>
  );
}

HubMyDashboardBanner.propTypes = {
  firstName: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
  showSelfSelectRoleModalAction: PropTypes.func,
  openSelfRegisteredWizardAction: PropTypes.func,
  localizedContent: PropTypes.object.isRequired,
  areOrgLoading: PropTypes.bool,
  areCoursesLoading: PropTypes.bool,
  areClassesLoading: PropTypes.bool
};

const mapDispatchToProps = {
  showSelfSelectRoleModalAction: showSelfSelectRoleModal,
  openSelfRegisteredWizardAction: openSelfRegisteredWizard
};

export default compose(
  connect(
    ({ organisationPage, hubCourses, search }) => ({
      areOrgLoading: organisationPage?.loading,
      areCoursesLoading: hubCourses?.loading,
      areClassesLoading: search?.profileClasses?.loading
    }),
    mapDispatchToProps
  ),
  withLocalizedContent('hubGlossary')
)(HubMyDashboardBanner);
