import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
// HOC
import Button from '@oup/shared-front-end/src/components/Button';
import TextLink from '@oup/shared-front-end/src/components/TextLink/TextLink';
import Link from '@oup/shared-front-end/src/components/Link/Link';
import { WORD_OF_DAY_BANNER } from '@oup/shared-front-end/src/svg/oup/index';
import withLocalizedContent from '../../language/withLocalizedContent';
// Styles
import styles from './WordOfTheDay.scss';
// Redux
import { getWordOfTheDayRequest } from '../../redux/actions/wordOfTheDay';
// Constants
import { HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants';
// Components
import HubEmptyStateRestyle from '../../components/HubEmptyStateRestyle/HubEmptyStateRestyle';
import SVGIcon, { GLYPHS } from '../../components/SVGIcon/SVGIcon';
import TileLoader from '../../components/SkeletonLoader/Hub/TileLoader';

function playAudio(url) {
  new Audio(url).play();
}

function WordOfTheDay({
  localizedContent: { hubGlossary: hubContent },
  wordContent,
  wordContentStatus,
  getWordOfTheDayAction,
  isLoading
}) {
  useEffect(() => {
    if (!wordContent) {
      getWordOfTheDayAction();
    }
  }, []);

  const emptyState = (
    <HubEmptyStateRestyle
      iconSrc={HubIllustrationConstants.WORD_OF_DAY_HEADER}
      iconAlt={HubIllustrationAltText.WORD_OF_DAY_HEADER}
      title={hubContent.word_of_the_day_empty_state_title}
      bodyText={hubContent.word_of_the_day_empty_state_subtitle}
    />
  );

  const wordOfTheDay = (
    <div>
      <span className={styles.srOnly}>{hubContent.word_of_the_day}</span>
      <WORD_OF_DAY_BANNER className={styles.wordHeader} alt={hubContent.word_of_the_day} />
      <div className={styles.content}>
        <div className={styles.word}>
          <Link target="_blank" to={wordContent?.entryUrl}>
            {wordContent?.entryLabel}
          </Link>
        </div>
        <div className={styles.levelPill}>
          {wordContent?.CEFRLevel ? <div className={styles.level}>{wordContent?.CEFRLevel}</div> : null}
          <div className={styles.category}>{wordContent?.textEntryPreview}</div>
        </div>
        <div className={styles.pronunciations}>
          <Button
            text={hubContent.british}
            lable={hubContent.british}
            size="small"
            variant="outline"
            icon={{ component: <SVGIcon glyph={GLYPHS.ICON_AUDIO_RESOURCE} /> }}
            onClick={() => playAudio(wordContent.audioUK)}
          />
          <Button
            text={hubContent.american}
            label={hubContent.american}
            size="small"
            variant="outline"
            icon={{ component: <SVGIcon glyph={GLYPHS.ICON_AUDIO_RESOURCE} /> }}
            onClick={() => playAudio(wordContent.audioUS)}
          />
        </div>
        <TextLink underline target="_blank" to={wordContent?.entryUrl}>
          {hubContent.student_dashboard_meaning}
        </TextLink>
        <div className={styles.world}>
          {hubContent.student_dashboard_from_list} {wordContent?.wordListType}:
        </div>
        <TextLink underline target="_blank" to={wordContent?.wordListUrl}>
          {wordContent?.wordListName}
        </TextLink>
      </div>
    </div>
  );

  const wordOfTheDayContent =
    wordContentStatus === 'success' ? (
      <div className={styles.wordOfTheDayContent}>{wordOfTheDay}</div>
    ) : (
      <div className={styles.emptyStateContainer}>{emptyState}</div>
    );

  return (
    <div className={styles.wordOfTheDayArea}>
      {isLoading && <TileLoader columnNumber={1} customClassName="wordOfTheDayTileSkeleton" />}
      {!isLoading && wordOfTheDayContent}
    </div>
  );
}

export default compose(
  connect(
    ({ wordOfTheDay = { content: {}, status: '' } }) => ({
      wordContent: wordOfTheDay.content,
      wordContentStatus: wordOfTheDay.status,
      isLoading: wordOfTheDay.isLoading
    }),
    {
      getWordOfTheDayAction: getWordOfTheDayRequest
    }
  ),
  withLocalizedContent('hubGlossary')
)(WordOfTheDay);
WordOfTheDay.propTypes = {
  wordContent: PropTypes.object,
  wordContentStatus: PropTypes.string,
  localizedContent: PropTypes.object.isRequired,
  getWordOfTheDayAction: PropTypes.func,
  isLoading: PropTypes.bool
};
