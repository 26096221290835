import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard';
import Footer from '@oup/shared-front-end/src/components/Wizard/Footer/Footer.js';
import { closeOnboardingWizard } from '../../../redux/actions/hubUi';
import { clearWizardState, setClassNameRequest } from '../../../redux/actions/classOnboardingWizard';
import { closeForm } from '../../../redux/reducers/classroomCreate.js';
import getClassLink from '../../../structure/HubDashboardLayout/Services/getClassLink';
import redirectToProgress from '../../../structure/HubDashboardLayout/Services/redirectToGradebookProgress';

function CreateClassWizardFooter({
  content,
  createClassRequestAction,
  userId,
  orgId,
  classId,
  classNameValue,
  classCreated,
  classCreationError,
  classCreationPending,
  clearClassroomCreateStateAction,
  closeWizardAction,
  clearWizardStateAction
}) {
  const { step, isLastStep, nextStep, nextStepDisabled } = useWizard();
  const { push } = useHistory();

  const setPrimaryButtonLabel = () => (isLastStep ? `${content.go_to} ${classNameValue}` : content.next);

  useEffect(() => {
    if (classCreated) {
      nextStep();
    }
  }, [classCreated]);

  const onCreateClassNextStep = () => {
    createClassRequestAction({ userId, orgId, classNameValue });

    if (classCreated && !classCreationError) {
      nextStep();
    }
  };

  const handleGoToClassClick = e => {
    redirectToProgress(e, getClassLink(classId), orgId, push);
    closeWizardAction();
    clearWizardStateAction();
    clearClassroomCreateStateAction();
  };

  const handlePrimaryStep = e => {
    switch (true) {
      case step === 1:
        onCreateClassNextStep();
        break;
      case isLastStep:
        handleGoToClassClick(e);
        break;
      default:
        nextStep();
        break;
    }
  };

  return (
    <Footer
      primaryButtonAction={handlePrimaryStep}
      primaryButtonLabel={setPrimaryButtonLabel()}
      primaryButtonAttributes={{
        disabled: nextStepDisabled,
        loading: classCreationPending,
        dataAttributes: {
          testid: 'CREATE_CLASS_WIZARD_NEXT_BUTTON'
        }
      }}
    />
  );
}

CreateClassWizardFooter.propTypes = {
  content: PropTypes.object.isRequired,
  classId: PropTypes.string,
  classCreationPending: PropTypes.bool,
  clearClassroomCreateStateAction: PropTypes.func,
  createClassRequestAction: PropTypes.func,
  userId: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
  classNameValue: PropTypes.string,
  classCreated: PropTypes.bool.isRequired,
  classCreationError: PropTypes.bool.isRequired,
  closeWizardAction: PropTypes.func,
  clearWizardStateAction: PropTypes.func
};

export default connect(
  ({ classOnboardingWizard, classroomCreate, identity }) => ({
    classNameValue: classroomCreate.classroomNameValue,
    classCreationPending: classOnboardingWizard.classNamePending,
    classCreated: classOnboardingWizard.classCreated,
    classCreationError: classOnboardingWizard.classCreationError,
    classId: classOnboardingWizard.classId,
    userId: identity.userId,
    orgId: identity.currentOrganisationId
  }),
  {
    createClassRequestAction: setClassNameRequest,
    clearWizardStateAction: clearWizardState,
    clearClassroomCreateStateAction: closeForm,
    closeWizardAction: closeOnboardingWizard
  }
)(CreateClassWizardFooter);
