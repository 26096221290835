import React from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';

function SideNavbarLoader({ isOpen }) {
  if (!isOpen) {
    return (
      <>
        <ContentLoader height="60" width="100%" viewBox="0 0 100% 60">
          <rect x="20" y="20" rx="5" ry="5" width="40%" height="28" />
        </ContentLoader>
        <ContentLoader height="60" width="100%" viewBox="0 0 100% 60">
          <rect x="20" y="20" rx="5" ry="5" width="40%" height="28" />
        </ContentLoader>
        <ContentLoader height="60" width="100%" viewBox="0 0 100% 60">
          <rect x="20" y="20" rx="5" ry="5" width="40%" height="28" />
        </ContentLoader>
        <ContentLoader height="60" width="100%" viewBox="0 0 100% 60">
          <rect x="15" y="20" rx="5" ry="5" width="60%" height="40" />
        </ContentLoader>
      </>
    );
  }

  return (
    <>
      <ContentLoader height="70" width="100%" viewBox="0 0 100% 70">
        <rect x="20" y="20" rx="5" ry="5" width="10%" height="28" />
        <rect x="65" y="10" rx="5" ry="5" width="60%" height="24" />
        <rect x="65" y="40" rx="5" ry="5" width="40%" height="18" />
      </ContentLoader>
      <ContentLoader height="70" width="100%" viewBox="0 0 100% 70">
        <rect x="20" y="20" rx="5" ry="5" width="10%" height="28" />
        <rect x="65" y="10" rx="5" ry="5" width="60%" height="24" />
        <rect x="65" y="40" rx="5" ry="5" width="40%" height="18" />
      </ContentLoader>
      <ContentLoader height="70" width="100%" viewBox="0 0 100% 70">
        <rect x="20" y="20" rx="5" ry="5" width="10%" height="28" />
        <rect x="65" y="10" rx="5" ry="5" width="60%" height="24" />
        <rect x="65" y="40" rx="5" ry="5" width="40%" height="18" />
      </ContentLoader>
      <ContentLoader height="70" width="100%" viewBox="0 0 100% 70">
        <rect x="20" y="20" rx="5" ry="5" width="90%" height="50" />
      </ContentLoader>
    </>
  );
}

SideNavbarLoader.propTypes = {
  isOpen: PropTypes.bool.isRequired
};

export default SideNavbarLoader;
