import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { useWizard } from '@oup/shared-front-end/src/components/Wizard';
import Heading from '@oup/shared-front-end/src/components/Heading';

import PlacementTestLanguageVariationContent from '../../../structure/HubOrganizationLayout/OrganizationPlacementTests/PlacementTestLanguageVariation/PlacementTestLanguageVariation';

import { OPT_YL, LANGUAGE_VARIATION } from '../utils/constants';
import withLocalizedContent from '../../../language/withLocalizedContent';
import {
  setEditedPlacementTest,
  setPlacementTestProductChanged
} from '../../../redux/reducers/placementTestSessionCreate';

import animationTimeout from '../../ClassOnboardingWizard/animationUtils/animationTimeout';
import animationStyles from '../../ClassOnboardingWizard/animationUtils/animationStyles.scss';
import styles from './PlacementTestLanguageVariation.scss';

function PlacementTestLanguageVariation({
  sessionName,
  setEditedPlacementTestAction,
  listeningAccentsCodeProp = '',
  languageVariationCodeProp = '',
  placementTestProductChangedAction,
  localizedContent: { placementTests: placementTestsContent }
}) {
  const { loading, stepHandler, setNextStepDisabled } = useWizard();
  const [selectedProduct, setSelectedProduct] = useState(false);
  const [placementTestType, setPlacementTestType] = useState('');
  const [languageVariationCode, setLanguageVariationCode] = useState(languageVariationCodeProp);
  const [listeningAccentsCode, setListeningAccentsCode] = useState(listeningAccentsCodeProp);
  const [stepOut, setStepOut] = useState(false);
  const [originalProduct, setOriginalProduct] = useState(undefined);
  const fadeOut = stepOut ? animationStyles.fadeOut : '';

  const handleNext = () => {
    const placementTest = {
      platform: 'am'
    };
    if (listeningAccentsCode) {
      placementTest.placementTestType = listeningAccentsCode;
      placementTest.listeningAccentsCode = listeningAccentsCode;
    } else placementTest.placementTestType = OPT_YL;
    if (placementTest.placementTestType !== OPT_YL) {
      placementTest.languageVariationCode = languageVariationCode;
    } else {
      placementTest.languageVariationCode = undefined;
    }
    if (selectedProduct && selectedProduct.productid) placementTest.productId = selectedProduct.productid;
    if (selectedProduct && selectedProduct.isbn) placementTest.isbn = selectedProduct.isbn;
    if (selectedProduct && selectedProduct['assessment_master:package_id'])
      placementTest.externalProductId = selectedProduct['assessment_master:package_id'];
    if (originalProduct && originalProduct !== selectedProduct.productid) {
      placementTestProductChangedAction();
    }
    if (placementTestType) {
      setEditedPlacementTestAction(placementTest);
    }
  };

  useEffect(() => {
    setNextStepDisabled(
      loading ||
        !placementTestType ||
        (placementTestType === LANGUAGE_VARIATION.OPT && (!languageVariationCode || !listeningAccentsCode))
    );
  }, [loading, placementTestType, languageVariationCode, listeningAccentsCode]);

  stepHandler(async () => {
    setStepOut(true);
    handleNext();
    await animationTimeout();
  });

  return (
    <div
      data-testid="ONBOARDING_WIZARD_CREATE_A_PLACEMENT_TEST_SESSION_TEST_TYPE_CONTAINER"
      className={`${fadeOut} ${styles.stepContainer}`}
    >
      <Heading
        text={`${placementTestsContent.placement_test_language_variation_title} ${sessionName}`}
        size="medium"
        className={styles.heading}
      />
      <PlacementTestLanguageVariationContent
        isWizard
        setStepOut={setStepOut}
        setSelectedProductCallback={data => setSelectedProduct(data)}
        setPlacementTestTypeCallback={data => setPlacementTestType(data)}
        setLanguageVariationCodeCallback={data => setLanguageVariationCode(data)}
        setListeningAccentsCodeCallback={data => setListeningAccentsCode(data)}
        setOriginalProductCallback={data => setOriginalProduct(data)}
      />
    </div>
  );
}

PlacementTestLanguageVariation.propTypes = {
  setEditedPlacementTestAction: PropTypes.func,
  sessionName: PropTypes.string,
  listeningAccentsCodeProp: PropTypes.string,
  languageVariationCodeProp: PropTypes.string,
  placementTestProductChangedAction: PropTypes.func,
  localizedContent: PropTypes.object.isRequired
};

export default compose(
  withLocalizedContent('placementTests'),
  connect(
    ({ placementTestSessionCreate, contentPreview }) => ({
      content: contentPreview.data || contentPreview.results,
      listeningAccentsCodeProp: placementTestSessionCreate.placementTest.listeningAccentsCode || '',
      languageVariationCodeProp: placementTestSessionCreate.placementTest.languageVariationCode || '',
      sessionName: placementTestSessionCreate.placementTestSessionNameValue || ''
    }),
    {
      setEditedPlacementTestAction: setEditedPlacementTest,
      placementTestProductChangedAction: setPlacementTestProductChanged
    }
  )
)(PlacementTestLanguageVariation);
