import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard';

import Heading from '@oup/shared-front-end/src/components/Heading/Heading.js';
import animationTimeout from '../../ClassOnboardingWizard/animationUtils/animationTimeout';
import ErrorStrip from '../../ErrorStrip/ErrorStrip';
import cmsContent from '../../../utils/cmsContent.js';
import userRoles from '../../../globals/userRoles';
import preventBodyScroll from '../../../utils/dom/preventBodyScroll';

import { GLYPHS } from '../../SVGIcon/SVGIcon';
import PlacementTestSessionNameInput from '../PlacementTestSessionInput/PlacementTestSessionInput';
import { setActiveClassId } from '../../../redux/reducers/classroomPage';
import { storeClassrooms } from '../../../redux/reducers/data/classrooms';
import { initialiseInstance } from '../../../redux/reducers/data/search.reducer';
import styles from './CreateAPlacementTest.scss';
import animationStyles from '../../ClassOnboardingWizard/animationUtils/animationStyles.scss';
import withLocalizedContent from '../../../language/withLocalizedContent';

function CreateAPlacementTest({
  placementTestSessionNameValue,
  placementTestSessionNameValidationPending,
  placementTestSessionNamePending,
  placementTestSessionNameValidationIsInvalid,
  placementTestSessionNameValidationMessage,
  placementTestSessionNameValidationIsWarning,
  placementTestSessionCreationError,
  placementTestSessionCreationLoading,
  userId,
  orgId,
  placementTestSessionId = '',
  setPlacementTestSessionPageIdAction,
  storePlacementTestSessionAction,
  initialiseInstanceAction,
  isOpen,
  localizedContent: { placementTests: placementTestsContent }
}) {
  const contentManagementSystem = cmsContent.placementTestSessionState || {};
  const { stepHandler, loading, setNextStepDisabled } = useWizard();
  const [stepOut, setStepOut] = useState(false);

  const checkingName =
    placementTestSessionNameValidationPending || placementTestSessionNamePending ? animationStyles.loading : '';
  const fadeOut = stepOut ? animationStyles.fadeOut : '';
  const emptyInput = !placementTestSessionNameValue;
  const invalidInput =
    (placementTestSessionNameValidationIsInvalid || placementTestSessionNameValidationIsWarning) &&
    (placementTestSessionNameValidationMessage ===
      contentManagementSystem.placement_test_session_name_special_character_error ||
      placementTestSessionNameValidationMessage === contentManagementSystem.placement_test_session_name_length_error ||
      placementTestSessionNameValidationMessage ===
        contentManagementSystem.unable_to_check_placement_test_session_name ||
      placementTestSessionNameValidationMessage === contentManagementSystem.placement_test_session_name_duplicate);

  useEffect(() => {
    setNextStepDisabled(!!checkingName || loading || emptyInput || invalidInput);
  }, [checkingName, loading, emptyInput, invalidInput]);

  useEffect(() => {
    preventBodyScroll(isOpen);
  });

  const updatePlacementTestSessionContext = () => {
    const createdPlacementTest = {
      [placementTestSessionId]: {
        teacherIdList: [userId],
        amountOfTeachers: 1,
        amountOfLearners: 0,
        studentIdList: [],
        archived: false,
        name: placementTestSessionNameValue,
        orgId
      }
    };
    storePlacementTestSessionAction(createdPlacementTest);
    setPlacementTestSessionPageIdAction(orgId, placementTestSessionId);
    initialiseInstanceAction('classStudents', false, {
      orgId,
      placementTestSessionId,
      active: true,
      invited: true,
      roles: [userRoles.LEARNER]
    });
    initialiseInstanceAction('profileClasses', false, { active: true, orgId, userId });
  };

  stepHandler(() => {
    setStepOut(true);
    updatePlacementTestSessionContext();
    animationTimeout();
  });

  return (
    <div
      className={`${styles.stepContainer} ${fadeOut}`}
      data-testid="ONBOARDING_WIZARD_CREATE_A_PLACEMENT_TEST_SESSION_CONTAINER"
    >
      <Heading text={placementTestsContent.create_placement_test_title} size="medium" className={styles.heading} />
      {placementTestSessionCreationError && (
        <ErrorStrip
          glyphCustomClass={styles.errorGlyph}
          errorCustomClass={styles.errorContainer}
          glyph={GLYPHS.ICON_VALIDATION_CROSS_ONBOARDING}
          message={placementTestsContent.error_creating_placement_test}
        />
      )}
      <PlacementTestSessionNameInput
        isInputDisabled={placementTestSessionCreationLoading}
        placementTestSessionNameLabel={placementTestsContent.placement_test_session_name}
        placementTestSessionNamePlaceholder={placementTestsContent.placement_test_session_name_placeholder}
      />
    </div>
  );
}

CreateAPlacementTest.propTypes = {
  placementTestSessionNameValue: PropTypes.string,
  placementTestSessionNameValidationPending: PropTypes.bool,
  placementTestSessionNameValidationIsInvalid: PropTypes.bool,
  placementTestSessionNamePending: PropTypes.bool,
  placementTestSessionNameValidationMessage: PropTypes.string,
  placementTestSessionNameValidationIsWarning: PropTypes.bool,
  placementTestSessionCreationError: PropTypes.bool,
  placementTestSessionCreationLoading: PropTypes.bool,
  userId: PropTypes.string,
  orgId: PropTypes.string,
  placementTestSessionId: PropTypes.string,
  setPlacementTestSessionPageIdAction: PropTypes.func,
  storePlacementTestSessionAction: PropTypes.func,
  initialiseInstanceAction: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  localizedContent: PropTypes.object.isRequired
};

export default compose(
  withLocalizedContent('hubGlossary', 'placementTests'),
  connect(
    ({ placementOnboardingWizard, placementTestSessionCreate, identity }) => ({
      placementTestSessionNameValue: placementTestSessionCreate.placementTestSessionNameValue,
      placementTestSessionNameValidationIsWarning:
        placementTestSessionCreate.placementTestSessionNameValidationIsWarning,
      placementTestSessionNameValidationPending: placementTestSessionCreate.placementTestSessionNameValidationPending,
      placementTestSessionNamePending: placementOnboardingWizard.placementTestSessionNamePending,
      placementTestSessionNameValidationIsInvalid:
        placementTestSessionCreate.placementTestSessionNameValidationIsInvalid,
      placementTestSessionNameValidationMessage: placementTestSessionCreate.placementTestSessionNameValidationMessage,
      placementTestSessionCreationError: placementOnboardingWizard.placementTestSessionCreationError,
      placementTestSessionCreationLoading: placementOnboardingWizard.placementTestSessionCreationLoading,
      placementTestSessionId: placementOnboardingWizard.placementTestSessionId,
      userId: identity.userId,
      orgId: identity.currentOrganisationId,
      userRole: identity.role
    }),
    {
      setPlacementTestSessionPageIdAction: setActiveClassId,
      storePlacementTestSessionAction: storeClassrooms,
      initialiseInstanceAction: initialiseInstance
    }
  )
)(CreateAPlacementTest);
