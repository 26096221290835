/* eslint-disable no-shadow */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get, has } from 'lodash';

import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Redux
import { connect } from 'react-redux';
import { compose } from 'recompose';

// Shared

import { getUsernameFromDummyEmail } from '@oup/shared-node-browser/supervisedUser';
import { ACCESS_CODE_TYPES, ORG_REGISTRATION_CONTEXT } from '@oup/shared-node-browser/constants';

// Style
import classnames from 'classnames';
import styles from './HubDashboardLayout.scss';
import joinClassStyles from '../../routes/JoinClass/JoinClass.scss';

import {
  openNavigation,
  closeNavigation,
  openRedeemModal,
  closeRedeemModal,
  openJoinClassWithCodeModal,
  openJoinPlacementTestWithCodeModal,
  closeJoinClassWithCodeModal,
  closeJoinPlacementTestWithCodeModal,
  closeEditMyAccountModal,
  selectMyDashboardView,
  showSelfSelectRoleModal,
  closeAddTeacherModal,
  closeCreateClassModal,
  toggleArchiveClassModal,
  toggleAddStudentsModal,
  toggleSetTestModalOpen,
  toggleAssessmentLicenceTab,
  openCreateClassModal,
  closeEditClassroomModal,
  closeEditOrgModal,
  toggleOrgUsagePanel,
  closeRecallLicenceModal,
  openOnboardingWizard,
  openSelfRegisteredWizard,
  openEnterCodeModal,
  closeEnterCodeModal,
  openPlacementTestOnboardingWizard
} from '../../redux/actions/hubUi';

import { loadCourses } from '../../redux/actions/hubCourses';
import { getUserDetails } from '../../redux/reducers/user/userProfile.reducer.js';
import { editUserIdentityRequest } from '../../redux/actions/editUser.js';
import { getOrgUsageRequest } from '../../redux/actions/orgUsage.js';
import { initialiseInstance } from '../../redux/reducers/data/search.reducer';
import { setActiveOrgId } from '../../redux/reducers/organisationPage.reducer';

// Constants
import breakpoints from '../../globals/breakpoints';
import { HubLayoutConstants, ONLINE_TEST_PANEL_CONTEXT } from '../../globals/hubConstants.js';

// Services
import userRoles, { roleIsAtLeast } from '../../globals/userRoles';
import localStorageWithCache from '../../utils/localStorageWithCache';
import getClassLink from './Services/getClassLink';
import { featureIsEnabled, socialIdpIsEnabled } from '../../globals/envSettings';
import { getCurrentPlatform } from '../../utils/platform.js';
import getOrganizationClassLink from './Utils/getOrganizationClassLink';

// HOC
import withLocalizedContent from '../../language/withLocalizedContent';
import withBreakpoint from '../../decorators/withBreakpoint';

// Components
import HubSideNavbar from './HubSideNavbar/HubSideNavbar';
import PopoutPanel from '../../components/PopoutPanel/PopoutPanel';
import AddToProfile from '../../routes/MyProfile/tabs/panels/AddToProfile/AddToProfile';
import MyProfileEditPanel from '../../panels/MyProfileEditPanel';
import SelfSelectRoleModal from './SelfSelectRoleModal';
import AddTeachers from '../../routes/ClassroomPage/tabs/TeachersTab/panels/AddTeachers/AddTeachers';
import AddStudents from '../../routes/ClassroomPage/tabs/StudentsTab/panels/AddStudents/AddStudents';
import StudentsAssessmentLicenceStatusTab from '../../routes/ClassroomPage/tabs/StudentsAssessmentLicenceStatusTab/StudentsAssessmentLicenceStatusTab';
import AddClassroom from '../../routes/MySchool/tabs/ClassroomsTab/panels/AddClassroom/AddClassroom';
import ArchiveClassroom from '../../routes/MySchool/tabs/ClassroomsTab/panels/ArchiveClassroom/ArchiveClassroom';
import EditClassroom from '../../routes/ClassroomEdit/EditClassroom';
import { EditOrgPanel } from '../../panels';
import LicenceRecallPanel from '../../routes/MyProfile/panels/licenceRecall/licenceRecallPanel';
import JoinClass from '../../routes/JoinClass/JoinClass';
import OnlineTestPanel from '../../panels/OnlineTestPanel';
import SystemNotificationBanner from '../SiteHeader/SystemNotificationBanner/SystemNotificationBanner';
import ClassOnboardingWizard from '../../components/ClassOnboardingWizard/ClassOnboardingWizard.js';
import PlacementTestOnboardingWizard from '../../components/PlacementTestOnBoardingWizard/PlacementTestOnboardingWizard';
import PlacementTestPanel from '../HubOrganizationLayout/OrganizationPlacementTests/PlacementTestPanel/PlacementTestPanel';
import PlacementTestSessionInformationModal from '../HubOrganizationLayout/OrganizationPlacementTests/PlacementTestSessionInformationModal/PlacementTestSessionInformationModal';
import ChooseRoleWizard from '../../components/SelfRegisteredUserOnboardingWizard/ChooseRoleWizard.js';
import EditUserAccount from '../../components/EditUserAccount/EditUserAccount.js';
import getPageLayoutData from './Utils/getPageLayoutData.js';
import EnterCodeModal from './EnterCodeModal/EnterCodeModal.js';
import OrgUsagePanel from '../../panels/OrgUsagePanel/OrgUsagePanel.js';
import OrgUsageBanner from '../../components/OrgUsageBanner/OrgUsageBanner.js';

class HubDashboardLayout extends Component {
  componentDidMount() {
    const {
      props: {
        loadCourses,
        openNavigation,
        breakpoint,
        location: { pathname },
        openRedeemModal,
        getUserDetails,
        currentOrganisationId,
        initialiseSearch,
        userId,
        userRole,
        setActiveOrgIdAction,
        getOrgUsageRequestAction,
        openOnboardingWizard,
        openPlacementTestOnboardingWizard
      },
      shouldOpenSelfRegisteredWizard
    } = this;
    const {
      PATH_NAMES: { DASHBOARD_REDEEM_PATH }
    } = HubLayoutConstants;
    const { removeKey } = localStorageWithCache;

    if (userRole === userRoles.ORG_ADMIN) {
      getOrgUsageRequestAction(currentOrganisationId);
    }
    setActiveOrgIdAction(currentOrganisationId);
    loadCourses();
    // Automatically opens the navigation for large screens
    if (breakpoint === breakpoints.LG) openNavigation();

    if (pathname === DASHBOARD_REDEEM_PATH) {
      openRedeemModal();
    }

    getUserDetails(currentOrganisationId, userId);

    initialiseSearch('profileClasses', false, { active: true, orgId: currentOrganisationId, userId });

    if (roleIsAtLeast(userRoles.LEARNER, userRole)) {
      removeKey('selectedRoleData');
    }

    const orgTypeFromLS = localStorage.getItem('selectedOrgType');
    if (orgTypeFromLS) {
      if (orgTypeFromLS === ORG_REGISTRATION_CONTEXT.PLACEMENT) openPlacementTestOnboardingWizard();
      if (orgTypeFromLS === ORG_REGISTRATION_CONTEXT.CES) openOnboardingWizard();
    } else shouldOpenSelfRegisteredWizard();
  }

  componentDidUpdate(prevProps) {
    const {
      props: {
        breakpoint,
        openNavigation,
        closeNavigation,
        location: { pathname },
        selfRegisteredWizardOpen
      },
      shouldOpenSelfRegisteredWizard
    } = this;

    // If resizing from lower screen size to larger then open the navigation
    if (prevProps.breakpoint !== breakpoint && breakpoint === breakpoints.LG) {
      openNavigation();
    }
    // If resizing from higher screen size to lower then close the navigation
    if (prevProps.breakpoint !== breakpoint && prevProps.breakpoint === breakpoints.LG) {
      closeNavigation();
    }

    // If pathname has changed and screen size is lower then 1180 then close the navigation
    if (prevProps.location.pathname !== pathname && breakpoint !== breakpoints.LG) {
      closeNavigation();
    }

    if (!selfRegisteredWizardOpen) shouldOpenSelfRegisteredWizard();
  }

  shouldOpenSelfRegisteredWizard = () => {
    const {
      props: {
        location: { pathname },
        currentOrganisationId,
        openSelfRegisteredWizardAction,
        registrationStatus,
        userPlatformStatistics
      }
    } = this;
    const {
      PATH_NAMES: { DASHBOARD_REDEEM_PATH }
    } = HubLayoutConstants;
    const { getKey } = localStorageWithCache;
    const selfSelectedRoleUserId = get(JSON.parse(getKey('selectedRoleData')), ['userId'], '');

    const displaySelfRegisteredWizard =
      featureIsEnabled('opt-main-features') &&
      registrationStatus === 'REGISTRATION_COMPLETE' &&
      !currentOrganisationId &&
      !selfSelectedRoleUserId &&
      pathname !== DASHBOARD_REDEEM_PATH;

    if (has(userPlatformStatistics, 'error') && userPlatformStatistics.error === false && displaySelfRegisteredWizard) {
      openSelfRegisteredWizardAction();
    }
  };

  addTeacherOnComplete = () => {
    const {
      props: { closeAddTeacherModal }
    } = this;

    closeAddTeacherModal();
  };

  addPeopleToClassOnSubmit = () => {
    const {
      props: {
        match: {
          params: { classroomId = '' }
        },
        history: { push },
        location: { pathname }
      }
    } = this;

    if (pathname.startsWith(getOrganizationClassLink(classroomId))) {
      return push(`${getOrganizationClassLink(classroomId)}/people`);
    }
    return push(`${getClassLink(classroomId)}/people`);
  };

  handleRedeemCodeOnComplete = () => {
    const {
      props: {
        history: { push },
        selectMyDashboardView,
        loadCourses
      }
    } = this;
    const {
      PATH_NAMES: { DASHBOARD_PATH },
      DASHBOARD_COURSES_VIEW
    } = HubLayoutConstants;
    selectMyDashboardView(DASHBOARD_COURSES_VIEW);
    push(DASHBOARD_PATH);
    loadCourses();
  };

  selectRole = (selfSelectedRole, wizardMode = false) => {
    const {
      props: { userId, showSelfSelectRoleModal, editIdentity, firstName, lastName }
    } = this;

    const data = {
      role: selfSelectedRole,
      userId
    };

    const { setKey } = localStorageWithCache;
    setKey('selectedRoleData', JSON.stringify(data));
    showSelfSelectRoleModal(wizardMode);

    // -- persist selfSelectedRole
    try {
      editIdentity({ userId, firstName, lastName, userType: selfSelectedRole });
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const {
      props: {
        testsData,
        breakpoint,
        children,
        closeRedeemModal,
        courses,
        areCoursesLoading,
        courseIds,
        classes,
        orgClasses,
        organization,
        classesIds,
        openNavigation,
        closeNavigation,
        openJoinClassWithCodeModal,
        openJoinPlacementTestWithCodeModal,
        closeJoinClassWithCodeModal,
        closeJoinPlacementTestWithCodeModal,
        joinClassWithCodeModalOpen = false,
        joinPlacementTestWithCodeModalOpen = false,
        openRedeemModal,
        redeemModalOpen,
        createClassModalOpen,
        openCreateClassModal,
        archiveClassPopupOpen,
        toggleArchiveClassModalAction,
        closeCreateClassModal,
        sideNavOpen,
        windowWidth,
        editAccountModalOpen,
        closeEditMyAccountModal,
        selectMyDashboardView,
        firstName,
        myDashboardView,
        selfSelectRoleModalOpen,
        areProfileClassesLoading,
        userRole,
        userEmail,
        userId,
        currentOrganisationId,
        match: {
          params: { courseId, classroomId }
        },
        location: { pathname },
        addTeachersModalOpen,
        statusStudentsAssessmenLicenceTab,
        assessmentTestId,
        closeAddTeacherModal,
        addStudentsModalOpen,
        setTestModalOpen,
        toggleAddStudentsModal,
        toggleSetTestModalOpen,
        toggleAssessmentLicenceTab,
        localizedContent: {
          hubGlossary: hubGlossaryContent,
          mySchoolClassTab: content,
          productFinder: productFinderContent,
          joiningAndProductCodes
        },
        editClassroomModalOpen,
        closeEditClassroomModal,
        editOrgModalOpen,
        closeEditOrgModal,
        orgUsagePanelOpen,
        toggleOrgUsagePanel,
        recallLicenceModalOpen,
        closeRecallLicenceModal,
        requireTCAcceptance,
        onboardingWizardOpen,
        placementTestOnboardingWizardOpen,
        openOnboardingWizard,
        currentOrganisationLti,
        placementTestPanelOpen,
        placementTestSessionInformationModalOpen,
        selfRegisteredWizardOpen,
        isLmsLtiTool,
        enterCodeModalOpen,
        openEnterCodeModal,
        closeEnterCodeModal
      },
      selectRole,
      addTeacherOnComplete,
      addPeopleToClassOnSubmit,
      handleRedeemCodeOnComplete
    } = this;

    const joiningAndProductCodesContent = featureIsEnabled('opt-main-features') ? joiningAndProductCodes : {};
    const hubContent = { ...hubGlossaryContent, ...joiningAndProductCodesContent } || {};
    const course = courses[courseId];
    const singleClass = orgClasses[classroomId];
    const pageLayout = getPageLayoutData({
      pathname,
      content: hubContent,
      course,
      isLmsLtiTool,
      currentOrganisationLti,
      userRole,
      orgData: {
        orgId: currentOrganisationId,
        orgShortCode: organization.customId,
        orgName: organization.name,
        orgConfig: organization.orgConfig
      },
      classData: {
        ...singleClass,
        classId: classroomId
      },
      userData: {
        userId,
        userRole
      }
    });

    const areClassesLoading = areProfileClassesLoading;

    const { getKey } = localStorageWithCache;
    const userIdFromLs = get(JSON.parse(getKey('selectedRoleData')), ['userId'], '');
    const flaggedRequireTCAcceptance = socialIdpIsEnabled(getCurrentPlatform()) && requireTCAcceptance;

    const showSelfSelectModal =
      !roleIsAtLeast(userRoles.LEARNER, userRole) &&
      !flaggedRequireTCAcceptance &&
      (selfSelectRoleModalOpen || userId !== userIdFromLs) &&
      !featureIsEnabled('opt-main-features');

    const showEditUserAccountForm = getUsernameFromDummyEmail(userEmail) !== null && !currentOrganisationId;

    const closeJoinPanel = () => {
      closeJoinClassWithCodeModal();
      closeJoinPlacementTestWithCodeModal();
    };

    if (selfRegisteredWizardOpen)
      return (
        <ChooseRoleWizard
          isOpen={selfRegisteredWizardOpen}
          firstName={firstName}
          selectRole={selectRole}
          selfSelectRoleModalOpen={selfSelectRoleModalOpen}
        />
      );

    if (featureIsEnabled('supervised-users') && showEditUserAccountForm) {
      return <EditUserAccount hubContent={hubContent} />;
    }

    return showSelfSelectModal ? (
      <>
        <SelfSelectRoleModal hubContent={hubContent} firstName={firstName} selectRole={selectRole} />
        <MyProfileEditPanel isOpen={editAccountModalOpen} closePanel={closeEditMyAccountModal} />
      </>
    ) : (
      <div className={styles.dashboardLayout}>
        <HubSideNavbar
          isOpen={sideNavOpen}
          pathname={pathname}
          breakpoint={breakpoint}
          openNavigation={openNavigation}
          openRedeemModal={openRedeemModal}
          openJoinClassWithCodeModal={openJoinClassWithCodeModal}
          openCreateClassModal={openCreateClassModal}
          openOnboardingWizard={openOnboardingWizard}
          openEnterCodeModal={openEnterCodeModal}
          closeNavigation={closeNavigation}
          hubContent={hubContent}
          userRole={userRole}
          organization={organization}
          currentOrganisationLti={currentOrganisationLti}
        />
        <div
          className={classnames(styles.dashboardContent, { [styles.navOpen]: sideNavOpen })}
          data-dashboard="dashboard"
        >
          <SystemNotificationBanner />
          {(userRole === userRoles.LEARNER || userRole === userRoles.USER) && (
            <div className={joinClassStyles.joinClassPage}>
              <Helmet title={content.join_class_with_code} />
              <PopoutPanel
                id="joinClassWithCode"
                ariaLabel={hubContent.join_class_with_code}
                isOpen={joinClassWithCodeModalOpen || joinPlacementTestWithCodeModalOpen}
              >
                <JoinClass closePanel={closeJoinPanel} onComplete={closeRedeemModal} />
              </PopoutPanel>
            </div>
          )}

          <ClassOnboardingWizard isOpen={onboardingWizardOpen} content={hubContent} breakpoint={breakpoint} />

          <PlacementTestOnboardingWizard isOpen={placementTestOnboardingWizardOpen} breakpoint={breakpoint} />

          <PlacementTestPanel isOpen={placementTestPanelOpen} />

          <PlacementTestSessionInformationModal isOpen={placementTestSessionInformationModalOpen} userRole={userRole} />

          <PopoutPanel id="redeemCode" ariaLabel="AddCode" isOpen={redeemModalOpen}>
            <AddToProfile
              closePanel={closeRedeemModal}
              onComplete={closeRedeemModal}
              onRedeemed={handleRedeemCodeOnComplete}
            />
          </PopoutPanel>

          {featureIsEnabled('learner-dashboard-refresh') && (
            <EnterCodeModal
              isOpen={enterCodeModalOpen}
              onClose={closeEnterCodeModal}
              onNext={selectedOption => {
                switch (selectedOption) {
                  case ACCESS_CODE_TYPES.CLASS:
                    closeEnterCodeModal();
                    openJoinClassWithCodeModal();
                    break;
                  case ACCESS_CODE_TYPES.PRODUCT:
                    closeEnterCodeModal();
                    openRedeemModal();
                    break;
                  case ACCESS_CODE_TYPES.PLACEMENT_TEST:
                    closeEnterCodeModal();
                    openJoinPlacementTestWithCodeModal();
                    break;
                  default:
                    return null;
                }
                return null;
              }}
            />
          )}

          <PopoutPanel
            id="addClassPopout"
            ariaLabel={content.aria_label_add_class_to_school}
            isOpen={createClassModalOpen}
            closeAction={closeCreateClassModal}
          >
            <AddClassroom
              orgId={currentOrganisationId}
              closePopoutAction={closeCreateClassModal}
              onComplete={closeCreateClassModal}
            />
          </PopoutPanel>
          <OnlineTestPanel
            closePanel={toggleSetTestModalOpen}
            isOpen={setTestModalOpen}
            context={ONLINE_TEST_PANEL_CONTEXT.CLASS_CONTEXT}
            panelFlow={{
              isCancelTestFlow: false,
              isEditTestFlow: false
            }}
            classroomId={classroomId}
          />
          <PopoutPanel
            id="archiveClassPopoup"
            dataTestId="ARCHIVE_CLASS_PANEL"
            ariaLabel={content.archive_class_text}
            isOpen={archiveClassPopupOpen}
            closeAction={toggleArchiveClassModalAction}
          >
            <ArchiveClassroom
              formState="REVIEW_SELECTIONS"
              closeArchiveAction={() => toggleArchiveClassModalAction()}
              backButtonAction={() => toggleArchiveClassModalAction()}
              onComplete={() => toggleArchiveClassModalAction()}
            />
          </PopoutPanel>
          <MyProfileEditPanel isOpen={editAccountModalOpen} closePanel={closeEditMyAccountModal} />
          <AddStudents
            isOpen={addStudentsModalOpen}
            closePanel={toggleAddStudentsModal}
            onComplete={toggleAddStudentsModal}
            onSubmit={addPeopleToClassOnSubmit}
            orgId={currentOrganisationId}
            classId={classroomId}
          />
          <AddTeachers
            isOpen={addTeachersModalOpen}
            closePanel={closeAddTeacherModal}
            orgId={currentOrganisationId}
            onComplete={addTeacherOnComplete}
            onSubmit={addPeopleToClassOnSubmit}
          />
          <StudentsAssessmentLicenceStatusTab
            isOpen={statusStudentsAssessmenLicenceTab}
            closePanel={toggleAssessmentLicenceTab}
            orgId={currentOrganisationId}
            testData={testsData[assessmentTestId]}
          />

          <PopoutPanel id="editClassPopout" ariaLabel="Edit Class" isOpen={editClassroomModalOpen}>
            <EditClassroom
              orgId={currentOrganisationId}
              classroomId={classroomId}
              closePopoutAction={closeEditClassroomModal}
            />
          </PopoutPanel>

          <PopoutPanel id="licenceRecall" ariaLabel={hubContent.recall_licence_aria} isOpen={recallLicenceModalOpen}>
            <LicenceRecallPanel closePanel={closeRecallLicenceModal} />
          </PopoutPanel>

          <EditOrgPanel opened={editOrgModalOpen} close={closeEditOrgModal} />

          {userRole === userRoles.ORG_ADMIN ? (
            <>
              <OrgUsagePanel
                content={hubContent}
                isOpen={orgUsagePanelOpen}
                handleClosePanel={toggleOrgUsagePanel}
                orgName={organization.name}
              />
              <OrgUsageBanner content={hubContent} />
            </>
          ) : null}

          {React.cloneElement(children, {
            courses,
            areCoursesLoading,
            courseIds,
            classes,
            classesIds,
            areClassesLoading,
            breakpoint,
            sideNavOpen,
            windowWidth,
            openRedeemModal,
            selectMyDashboardView,
            hubContent,
            productFinderContent,
            firstName,
            myDashboardView,
            pageLayout
          })}
        </div>
      </div>
    );
  }
}

export default compose(
  connect(
    ({
      hubCourses: { courses = {}, loading = true, courseIds = [] } = {},
      identity: {
        missingFields,
        userId,
        role: userRole,
        email: userEmail,
        currentOrganisationId,
        currentOrganisationLti,
        registrationStatus
      },
      hubUi: {
        sideNavOpen,
        redeemModalOpen,
        enterCodeModalOpen,
        structuredContentPlayerModalOpen,
        structuredContentPlayerModalClose,
        joinClassWithCodeModalOpen,
        joinPlacementTestWithCodeModalOpen,
        editAccountModalOpen,
        myDashboardView,
        selfSelectRoleModalOpen,
        addTeachersModalOpen,
        statusStudentsAssessmenLicenceTab,
        assessmentTestId,
        createClassModalOpen,
        archiveClassPopupOpen,
        addStudentsModalOpen,
        setTestModalOpen,
        editClassroomModalOpen,
        editOrgModalOpen,
        orgUsagePanelOpen,
        recallLicenceModalOpen,
        removeClassMaterialModalOpen,
        onboardingWizardOpen,
        placementTestOnboardingWizardOpen,
        placementTestPanelOpen,
        placementTestSessionInformationModalOpen,
        selfRegisteredWizardOpen
      },
      organisations: { data: orgs = {} } = {},
      classrooms: { data: orgClasses = {} } = {},
      people: { data = {} } = {},
      search: {
        profileClasses: { currentClassList = {}, ids = [], loading: areProfileClassesLoading = false } = {}
      } = {},
      hubPeople: { endSlice = 0 } = {},
      classAssessments: { classTests: testsData = {} } = {},
      organisationPage: { isLmsLtiTool },
      userPlatformStatistics
    }) => ({
      testsData,
      courses,
      courseIds,
      areCoursesLoading: loading,
      classes: currentClassList,
      orgClasses,
      organization: orgs[currentOrganisationId] || {},
      classesIds: ids,
      areProfileClassesLoading,
      sideNavOpen,
      redeemModalOpen,
      enterCodeModalOpen,
      structuredContentPlayerModalOpen,
      structuredContentPlayerModalClose,
      joinClassWithCodeModalOpen,
      joinPlacementTestWithCodeModalOpen,
      createClassModalOpen,
      archiveClassPopupOpen,
      editAccountModalOpen,
      userId,
      userRole,
      userEmail,
      currentOrganisationId,
      currentOrganisationLti,
      registrationStatus,
      firstName: get(data, [userId, 'firstname']),
      lastName: get(data, [userId, 'lastname']),
      myDashboardView,
      selfSelectRoleModalOpen,
      addTeachersModalOpen,
      statusStudentsAssessmenLicenceTab,
      assessmentTestId,
      addStudentsModalOpen,
      setTestModalOpen,
      endSlice,
      editClassroomModalOpen,
      editOrgModalOpen,
      orgUsagePanelOpen,
      recallLicenceModalOpen,
      removeClassMaterialModalOpen,
      requireTCAcceptance: missingFields.includes('tsAndCsAccepted'),
      onboardingWizardOpen,
      placementTestOnboardingWizardOpen,
      placementTestPanelOpen,
      placementTestSessionInformationModalOpen,
      selfRegisteredWizardOpen,
      isLmsLtiTool,
      userPlatformStatistics
    }),
    {
      loadCourses,
      initialiseSearch: initialiseInstance,
      setActiveOrgIdAction: setActiveOrgId,
      openNavigation,
      closeNavigation,
      openRedeemModal,
      closeRedeemModal,
      openEnterCodeModal,
      closeEnterCodeModal,
      openJoinClassWithCodeModal,
      openJoinPlacementTestWithCodeModal,
      closeJoinClassWithCodeModal,
      closeJoinPlacementTestWithCodeModal,
      closeCreateClassModal,
      closeEditMyAccountModal,
      getUserDetails,
      selectMyDashboardView,
      showSelfSelectRoleModal,
      closeAddTeacherModal,
      toggleArchiveClassModalAction: toggleArchiveClassModal,
      toggleAddStudentsModal,
      toggleSetTestModalOpen,
      toggleAssessmentLicenceTab,
      openCreateClassModal,
      closeEditClassroomModal,
      closeEditOrgModal,
      toggleOrgUsagePanel,
      closeRecallLicenceModal,
      openOnboardingWizard,
      editIdentity: editUserIdentityRequest,
      openSelfRegisteredWizardAction: openSelfRegisteredWizard,
      getOrgUsageRequestAction: getOrgUsageRequest,
      openPlacementTestOnboardingWizard
    }
  ),
  withLocalizedContent(
    'hubGlossary',
    'mySchoolClassTab',
    'onboardingWizard',
    'productFinder',
    'joiningAndProductCodes',
    'enterCode'
  ),
  withBreakpoint
)(withRouter(HubDashboardLayout));

HubDashboardLayout.propTypes = {
  testsData: PropTypes.object,
  children: PropTypes.element.isRequired,
  courses: PropTypes.object.isRequired,
  areCoursesLoading: PropTypes.bool.isRequired,
  courseIds: PropTypes.array.isRequired,
  classes: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  orgClasses: PropTypes.object.isRequired,
  classesIds: PropTypes.array.isRequired,
  areProfileClassesLoading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  loadCourses: PropTypes.func.isRequired,
  initialiseSearch: PropTypes.func.isRequired,
  setActiveOrgIdAction: PropTypes.func,
  breakpoint: PropTypes.string.isRequired,
  windowWidth: PropTypes.number.isRequired,
  currentOrganisationId: PropTypes.string,
  currentOrganisationLti: PropTypes.bool,
  registrationStatus: PropTypes.string,
  organization: PropTypes.object,
  userId: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  userEmail: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string,
  match: PropTypes.object.isRequired,
  localizedContent: PropTypes.object.isRequired,
  sideNavOpen: PropTypes.bool.isRequired,
  openNavigation: PropTypes.func.isRequired,
  closeNavigation: PropTypes.func.isRequired,
  joinClassWithCodeModalOpen: PropTypes.bool,
  joinPlacementTestWithCodeModalOpen: PropTypes.bool,
  closeJoinClassWithCodeModal: PropTypes.func.isRequired,
  closeJoinPlacementTestWithCodeModal: PropTypes.func.isRequired,
  openJoinClassWithCodeModal: PropTypes.func.isRequired,
  openJoinPlacementTestWithCodeModal: PropTypes.func.isRequired,
  redeemModalOpen: PropTypes.bool.isRequired,
  openRedeemModal: PropTypes.func.isRequired,
  closeRedeemModal: PropTypes.func.isRequired,
  enterCodeModalOpen: PropTypes.bool.isRequired,
  openEnterCodeModal: PropTypes.func.isRequired,
  closeEnterCodeModal: PropTypes.func.isRequired,
  editAccountModalOpen: PropTypes.bool,
  closeEditMyAccountModal: PropTypes.func.isRequired,
  getUserDetails: PropTypes.func.isRequired,
  selectMyDashboardView: PropTypes.func.isRequired,
  showSelfSelectRoleModal: PropTypes.func.isRequired,
  myDashboardView: PropTypes.string.isRequired,
  selfSelectRoleModalOpen: PropTypes.bool.isRequired,
  addStudentsModalOpen: PropTypes.bool,
  setTestModalOpen: PropTypes.bool,
  toggleAddStudentsModal: PropTypes.func.isRequired,
  toggleSetTestModalOpen: PropTypes.func,
  toggleAssessmentLicenceTab: PropTypes.func,
  createClassModalOpen: PropTypes.bool,
  openCreateClassModal: PropTypes.func.isRequired,
  closeCreateClassModal: PropTypes.func.isRequired,
  toggleArchiveClassModalAction: PropTypes.func,
  archiveClassPopupOpen: PropTypes.bool,
  addTeachersModalOpen: PropTypes.bool,
  statusStudentsAssessmenLicenceTab: PropTypes.bool,
  assessmentTestId: PropTypes.string,
  closeAddTeacherModal: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  editClassroomModalOpen: PropTypes.bool,
  closeEditClassroomModal: PropTypes.func.isRequired,
  editOrgModalOpen: PropTypes.bool,
  closeEditOrgModal: PropTypes.func,
  recallLicenceModalOpen: PropTypes.bool,
  closeRecallLicenceModal: PropTypes.func.isRequired,
  requireTCAcceptance: PropTypes.bool,
  onboardingWizardOpen: PropTypes.bool,
  placementTestOnboardingWizardOpen: PropTypes.bool,
  openOnboardingWizard: PropTypes.func.isRequired,
  placementTestPanelOpen: PropTypes.bool,
  placementTestSessionInformationModalOpen: PropTypes.bool,
  editIdentity: PropTypes.func.isRequired,
  selfRegisteredWizardOpen: PropTypes.bool,
  openSelfRegisteredWizardAction: PropTypes.func,
  isLmsLtiTool: PropTypes.bool,
  userPlatformStatistics: PropTypes.object,
  orgUsagePanelOpen: PropTypes.bool,
  toggleOrgUsagePanel: PropTypes.func,
  getOrgUsageRequestAction: PropTypes.func,
  openPlacementTestOnboardingWizard: PropTypes.func
};
