import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'recompose';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard';
import { Heading } from '@oup/shared-front-end/src/index.js';
import RadioButtonGroup from '@oup/shared-front-end/src/components/RadioButtonGroup/RadioButtonGroup';
import { AUTH_OPTIONS } from '../../globals/appConstants.js';
import withLocalizedContent from '../../language/withLocalizedContent';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants';
import styles from './RegisterOrSignInStep.scss';

function RegisterOrSignInStep({ localizedContent: { redeemCodeWizard: content } }) {
  const { setNextStepDisabled } = useWizard();
  const [selectedUserOption, setSelectedUserOption] = useState('');

  useEffect(() => {
    setNextStepDisabled(!selectedUserOption);
  }, [selectedUserOption]);

  return (
    <div data-testid="REDEEM_CODE_WIZARD_STEP_3" className={styles.stepContainer}>
      <div className={styles.leftContainer}>
        <Heading text={content.register_or_sign_in} size="medium" className={styles.heading} />
        <RadioButtonGroup
          variant="outline"
          name="registerOrSignIn"
          classnames={{ container: styles.radioButtonGroupContainer }}
          checkedValue={selectedUserOption}
          legend={
            selectedUserOption === AUTH_OPTIONS.REGISTER ? content.choose_register_legend : content.choose_login_legend
          }
          radioGroup={[
            {
              id: AUTH_OPTIONS.LOGIN,
              label: content.sign_in_option,
              value: AUTH_OPTIONS.LOGIN
            },
            {
              id: AUTH_OPTIONS.REGISTER,
              label: content.register_option,
              value: AUTH_OPTIONS.REGISTER
            }
          ]}
          onChange={e => setSelectedUserOption(e.target.value)}
        />
      </div>
      <div className={styles.rightContainer}>
        <img className={styles.image} src={HubIllustrationConstants.SUCCESS} alt={HubIllustrationAltText.SUCCESS} />
      </div>
    </div>
  );
}

RegisterOrSignInStep.propTypes = { localizedContent: PropTypes.object.isRequired };

export default compose(withLocalizedContent('redeemCodeWizard'))(RegisterOrSignInStep);
