import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import { useWizard } from '@oup/shared-front-end/src/components/Wizard';
import Heading from '@oup/shared-front-end/src/components/Heading';

import PlacementTestStartEndDate from './PlacementTestStartEndDate';
import PlacementTestExtraTime from './PlacementTestExtraTime';
import { setEditedPlacementTest } from '../../../redux/reducers/placementTestSessionCreate';
import withLocalizedContent from '../../../language/withLocalizedContent';

import styles from './PlacementTestSchedule.scss';
import animationTimeout from '../../ClassOnboardingWizard/animationUtils/animationTimeout';
import animationStyles from '../../ClassOnboardingWizard/animationUtils/animationStyles.scss';

function PlacementTestSchedule({
  placementTestSessionNameValue,
  testStartDate = '',
  testEndDate = '',
  extraTimePerItem = 0,
  setEditedPlacementTestAction,
  placementTestSessionIdCreated,
  localizedContent: { placementTests: placementTestsContent }
}) {
  const [editedStartDate, setEditedStartDate] = useState(testStartDate);
  const [editedEndDate, setEditedEndDate] = useState(testEndDate);
  const [editedExtraTimePerItem, setEditedExtraTimePerItem] = useState(extraTimePerItem);

  const [editedStartDareIsInvalid, setEditedStartDateIsInvalid] = useState(false);
  const [editedEndDateIsInvalid, setEditedEndDateIsInvalid] = useState(false);
  const [editedExtraTimePerItemIsInvalid, setEditedExtraTimePerItemIsInvalid] = useState(false);

  const { stepHandler, loading, setNextStepDisabled, nextStep } = useWizard();
  const [stepOut, setStepOut] = useState(false);
  const fadeOut = stepOut ? animationStyles.fadeOut : '';

  setNextStepDisabled(loading || editedStartDareIsInvalid || editedEndDateIsInvalid || editedExtraTimePerItemIsInvalid);

  stepHandler(async () => {
    setStepOut(true);
    setEditedPlacementTestAction({
      testStartDate: editedStartDate,
      testEndDate: editedEndDate,
      extraTimePerItem: editedExtraTimePerItem
    });
    await animationTimeout();
  });

  useEffect(() => {
    if (placementTestSessionIdCreated) {
      nextStep();
    }
  }, [placementTestSessionIdCreated]);

  return (
    <div
      data-testid="ONBOARDING_WIZARD_CREATE_A_PLACEMENT_TEST_SESSION_SCHEDULE_CONTAINER"
      className={`${fadeOut} ${styles.stepContainer}`}
    >
      <Heading
        text={`${placementTestsContent.create_placement_test_schedule_title} ${placementTestSessionNameValue}`}
        size="medium"
        className={styles.heading}
      />
      <PlacementTestStartEndDate
        testStartDate={editedStartDate}
        setTestStartDate={setEditedStartDate}
        setTestStartDateIsInvalid={setEditedStartDateIsInvalid}
        testEndDate={editedEndDate}
        setTestEndDate={setEditedEndDate}
        setTestEndDateIsInvalid={setEditedEndDateIsInvalid}
      />
      <PlacementTestExtraTime
        extraTimePerItem={editedExtraTimePerItem}
        setExtraTimePerItem={setEditedExtraTimePerItem}
        setExtraTimePerItemIsInvalid={setEditedExtraTimePerItemIsInvalid}
      />
    </div>
  );
}

PlacementTestSchedule.propTypes = {
  placementTestSessionNameValue: PropTypes.string.isRequired,
  testStartDate: PropTypes.string.isRequired,
  testEndDate: PropTypes.string.isRequired,
  extraTimePerItem: PropTypes.number.isRequired,
  setEditedPlacementTestAction: PropTypes.func,
  placementTestSessionIdCreated: PropTypes.string,
  localizedContent: PropTypes.object.isRequired
};

export default compose(
  withLocalizedContent('placementTests'),
  connect(
    ({ placementTestSessionCreate, placementOnboardingWizard }) => ({
      placementTestSessionNameValue: placementTestSessionCreate.placementTestSessionNameValue,
      testStartDate: placementTestSessionCreate.placementTest.testStartDate,
      testEndDate: placementTestSessionCreate.placementTest.testEndDate,
      extraTimePerItem: placementTestSessionCreate.placementTest.extraTimePerItem,
      testStartDateIsInvalid: placementTestSessionCreate.testStartDateIsInvalid,
      placementTestSessionIdCreated: placementOnboardingWizard.placementTestSessionIdCreated
    }),
    {
      setEditedPlacementTestAction: setEditedPlacementTest
    }
  )
)(PlacementTestSchedule);
